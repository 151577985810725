import React from "react";

const LogoSvg: React.FC = () => {
  return (
    <svg width="128" height="35" viewBox="0 0 128 35" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-auto h-8 sm:h-8 fade-in">
      <g clipPath="url(#clip0_1_23)">
        <path
          d="M54.0547 17.8212L63.3541 32.7094C64.8297 35.0716 68.2446 35.1024 69.7622 32.7672L87.8512 4.93107C89.0894 3.02557 87.7287 0.5 85.4639 0.5C84.4925 0.5 83.5879 0.99695 83.0636 1.81865L69.6851 22.7869C68.4222 24.7662 65.5438 24.7662 64.281 22.7869L63.7906 22.0185C62.6331 20.2041 63.9296 17.8212 66.0746 17.8212C66.9989 17.8212 67.8596 17.3484 68.3586 16.5665L68.4058 16.4924C69.6317 14.571 68.2586 12.0475 65.9871 12.0475H57.2324C54.2825 12.0475 52.4864 15.3106 54.0547 17.8212Z"
          className="fill-current text-blue-600 dark:text-blue-600"
        />
        <path
          d="M92.2472 34.4173C90.5756 34.4173 89.113 34.0393 87.8592 33.2836C86.6334 32.5277 85.6722 31.4499 84.9757 30.0502C84.3071 28.6226 83.9727 26.9289 83.9727 24.9693V13.1699C83.9727 12.8899 84.0702 12.6521 84.2653 12.456C84.4603 12.2601 84.6971 12.1621 84.9757 12.1621H88.8621C89.1687 12.1621 89.4194 12.2601 89.6144 12.456C89.8095 12.6521 89.907 12.8899 89.907 13.1699V24.7174C89.907 28.0767 91.3696 29.7563 94.295 29.7563C95.7158 29.7563 96.8442 29.3224 97.68 28.4546C98.5158 27.5588 98.9337 26.3131 98.9337 24.7174V13.1699C98.9337 12.8899 99.0173 12.6521 99.1845 12.456C99.3795 12.2601 99.6302 12.1621 99.9366 12.1621H103.781C104.088 12.1621 104.339 12.2601 104.534 12.456C104.729 12.6521 104.826 12.8899 104.826 13.1699V32.9896C104.826 33.2696 104.729 33.5074 104.534 33.7034C104.339 33.8994 104.088 33.9973 103.781 33.9973H100.187C99.9088 33.9973 99.672 33.8994 99.477 33.7034C99.282 33.5074 99.1845 33.2696 99.1845 32.9896V31.2679C98.46 32.2757 97.5267 33.0596 96.3845 33.6194C95.27 34.1513 93.891 34.4173 92.2472 34.4173Z"
          className="fill-current text-white dark:text-white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M117.093 34.4173C114 34.4173 111.534 33.5214 109.696 31.7298C107.857 29.9382 106.868 27.3768 106.729 24.0455V23.0377V22.0299C106.84 19.9024 107.314 18.0688 108.149 16.5292C109.013 14.9895 110.197 13.8138 111.702 13.0019C113.234 12.1621 115.031 11.7422 117.093 11.7422C119.377 11.7422 121.286 12.2181 122.818 13.1699C124.35 14.1217 125.507 15.4374 126.286 17.117C127.094 18.7687 127.498 20.6723 127.498 22.8278V23.7515C127.498 24.0315 127.401 24.2694 127.206 24.4654C127.01 24.6614 126.761 24.7593 126.453 24.7593H112.621V24.8853V25.0953C112.677 26.047 112.872 26.9289 113.206 27.7407C113.54 28.5245 114.028 29.1684 114.669 29.6723C115.337 30.1482 116.145 30.3862 117.093 30.3862C117.817 30.3862 118.43 30.2742 118.931 30.0502C119.461 29.8263 119.879 29.5603 120.185 29.2524C120.519 28.9444 120.756 28.7065 120.895 28.5385C121.146 28.2025 121.341 28.0067 121.481 27.9507C121.62 27.8667 121.857 27.8247 122.191 27.8247H125.994C126.272 27.8247 126.496 27.9087 126.663 28.0767C126.857 28.2445 126.941 28.4545 126.914 28.7065C126.886 29.1544 126.649 29.7143 126.203 30.3862C125.785 31.03 125.158 31.6598 124.322 32.2757C123.514 32.8915 122.497 33.4094 121.272 33.8294C120.074 34.2213 118.681 34.4173 117.093 34.4173ZM121.606 21.1482H112.621V21.0222C112.621 19.9584 112.788 19.0345 113.122 18.2508C113.485 17.4389 114 16.809 114.669 16.3612C115.337 15.9133 116.145 15.6893 117.093 15.6893C118.04 15.6893 118.848 15.9133 119.516 16.3612C120.185 16.809 120.7 17.4389 121.063 18.2508C121.425 19.0345 121.606 19.9584 121.606 21.0222V21.1482Z"
          className="fill-current text-white dark:text-white"
        />
        <path
          d="M32.9054 33.9973C32.6268 33.9973 32.39 33.8994 32.195 33.7034C31.9998 33.5074 31.9023 33.2695 31.9023 32.9895V13.1699C31.9023 12.8899 31.9998 12.652 32.195 12.456C32.39 12.2601 32.6268 12.1621 32.9054 12.1621H36.4993C36.8059 12.1621 37.0566 12.2601 37.2516 12.456C37.4466 12.652 37.5441 12.8899 37.5441 13.1699V14.8915C38.2685 13.9957 39.1878 13.2539 40.3023 12.666C41.4445 12.0501 42.8515 11.7422 44.5231 11.7422C46.1947 11.7422 47.6574 12.1201 48.9112 12.8759C50.1648 13.6318 51.1259 14.7235 51.7947 16.1512C52.4911 17.5509 52.8394 19.2305 52.8394 21.1902V32.9895C52.8394 33.2695 52.7419 33.5074 52.5469 33.7034C52.3797 33.8994 52.1429 33.9973 51.8365 33.9973H47.9499C47.6713 33.9973 47.4345 33.8994 47.2395 33.7034C47.0445 33.5074 46.947 33.2695 46.947 32.9895V21.442C46.947 19.8464 46.557 18.6147 45.7768 17.7469C45.0246 16.851 43.9101 16.4032 42.4336 16.4032C41.0127 16.4032 39.8844 16.851 39.0485 17.7469C38.2127 18.6147 37.7949 19.8464 37.7949 21.442V32.9895C37.7949 33.2695 37.6974 33.5074 37.5023 33.7034C37.3073 33.8994 37.0705 33.9973 36.7919 33.9973H32.9054Z"
          className="fill-current text-white dark:text-white"
        />
        <path
          d="M23.4777 33.9973C23.1991 33.9973 22.9623 33.8994 22.7671 33.7034C22.5721 33.5074 22.4746 33.2696 22.4746 32.9896V13.1699C22.4746 12.8899 22.5721 12.6521 22.7671 12.456C22.9623 12.2601 23.1991 12.1621 23.4777 12.1621H27.1551C27.4616 12.1621 27.6985 12.2601 27.8657 12.456C28.0607 12.6521 28.1582 12.8899 28.1582 13.1699V32.9896C28.1582 33.2696 28.0607 33.5074 27.8657 33.7034C27.6985 33.8994 27.4616 33.9973 27.1551 33.9973H23.4777Z"
          className="fill-current text-white dark:text-white"
        />
        <path
          d="M23.3107 8.6346C23.0321 8.6346 22.7953 8.53663 22.6001 8.34066C22.4051 8.14471 22.3076 7.89276 22.3076 7.58483V4.47751C22.3076 4.19756 22.4051 3.95963 22.6001 3.76366C22.7953 3.5677 23.0321 3.46973 23.3107 3.46973H27.3225C27.6012 3.46973 27.838 3.5677 28.033 3.76366C28.228 3.95963 28.3255 4.19756 28.3255 4.47751V7.58483C28.3255 7.89276 28.228 8.14471 28.033 8.34066C27.838 8.53663 27.6012 8.6346 27.3225 8.6346H23.3107Z"
          className="fill-current text-white dark:text-white"
        />
        <path
          d="M1.00297 33.9971C0.724367 33.9971 0.487554 33.8992 0.292533 33.7032C0.097511 33.5072 0 33.2693 0 32.9893V5.65329C0 5.34535 0.097511 5.09342 0.292533 4.89745C0.487554 4.7015 0.724367 4.60352 1.00297 4.60352H19.3072C19.6136 4.60352 19.8504 4.7015 20.0176 4.89745C20.2126 5.09342 20.3102 5.34535 20.3102 5.65329V8.76062C20.3102 9.06854 20.2126 9.32049 20.0176 9.51645C19.8504 9.7124 19.6136 9.81039 19.3072 9.81039H5.93424V17.5367H18.4714C18.7779 17.5367 19.0146 17.6347 19.1818 17.8307C19.3768 17.9986 19.4743 18.2366 19.4743 18.5444V21.6938C19.4743 21.9737 19.3768 22.2117 19.1818 22.4077C19.0146 22.6036 18.7779 22.7016 18.4714 22.7016H5.93424V32.9893C5.93424 33.2693 5.83672 33.5072 5.64171 33.7032C5.47455 33.8992 5.23773 33.9971 4.93127 33.9971H1.00297Z"
          className="fill-current text-white dark:text-white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_23">
          <rect width="128" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoSvg;
