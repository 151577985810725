import { ArrowDownCircleIcon, ArrowUpCircleIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

interface JobsSectionProps {
  dataIds: number[];
}

export const jobs = [
  {
    id: 1,
    question: "Web Developer",
    answer: (
      <>
        <p>Join our dynamic team as a Web Developer and help us build scalable and innovative web applications. We're looking for someone who is passionate about coding and enjoys working on challenging projects.</p>
        <ul className="list-disc ml-5 mt-2 text-gray-400">
          <li>Develop and maintain user-friendly websites and web applications.</li>
          <li>Collaborate with designers and backend developers to create seamless experiences.</li>
          <li>Stay updated with the latest web development technologies and trends.</li>
        </ul>
      </>
    ),
    link: "/apply/#Web%20Developer",
  },
  {
    id: 2,
    question: "Sales Team Member",
    answer: (
      <>
        <p>Be a part of our sales team and drive our business growth by building strong relationships with our clients. If you're energetic and love to connect with people, this role is for you!</p>
        <ul className="list-disc ml-5 mt-2 text-gray-400">
          <li>Identify and nurture leads to grow our customer base.</li>
          <li>Develop and deliver compelling sales pitches to clients.</li>
          <li>Collaborate with internal teams to ensure customer satisfaction.</li>
        </ul>
      </>
    ),
    link: "/apply/#Sales%20Team%20Member",
  },
  {
    id: 3,
    question: "Product Manager",
    answer: (
      <>
        <p>We are looking for a Product Manager who can oversee the lifecycle of our innovative products and ensure they meet the needs of our customers.</p>
        <ul className="list-disc ml-5 mt-2 text-gray-400">
          <li>Define product vision, strategy, and roadmap.</li>
          <li>Collaborate with cross-functional teams to deliver high-quality products.</li>
          <li>Conduct market research to identify new opportunities.</li>
        </ul>
      </>
    ),
    link: "/apply/#Product%20Manager",
  },
];

const JobsList: React.FC<JobsSectionProps> = ({ dataIds }) => {
  const [openId, setOpenId] = useState<number | null>(null);

  const toggleFaq = (id: number) => {
    setOpenId(openId === id ? null : id);
  };

  const filteredJobs = jobs.filter((job) => dataIds.includes(job.id));

  return (
    <section className="relative py-4 overflow-hidden text-slate-800">
      <div className="relative mx-auto">
        <div className="max-w-5xl mx-auto">
          <div className="text-center mb-12 px-4">
            <h1 className="font-heading text-3xl md:text-5xl font-bold">Our Openings</h1>
            <p className="text-base text-blue-500 mt-6">Join us and empower the next generation of business.</p>
          </div>
          <div className="py-16 pb-10 px-8 sm:px-20 bg-neutral-100 dark:bg-black dark:bg-opacity-20 rounded-xl xl:rounded-[40px]">
            {filteredJobs.map((job) => (
              <div key={job.id} className="flex flex-col mb-8 pb-8 group w-full items-start justify-between border-b border-gray-700 text-left cursor-pointer">
                <div className="flex items-center justify-between w-full">
                  {/* Job Title */}
                  <h3 className={`text-xl font-semibold ${openId === job.id ? "text-slate-950" : "text-black"}`} onClick={() => toggleFaq(job.id)}>
                    {job.question}
                  </h3>
                  {/* Arrow Icon */}
                  <div className="ml-4 cursor-pointer" onClick={() => toggleFaq(job.id)}>
                    {openId === job.id ? <ArrowUpCircleIcon className="h-6 w-6 text-black" /> : <ArrowDownCircleIcon className="h-6 w-6 text-black" />}
                  </div>
                </div>

                {/* Job Description */}
                <div className={`overflow-hidden transition-all duration-500 ${openId === job.id ? "h-auto mt-3" : "h-0"}`}>
                  <div className="text-lg text-gray-400">{job.answer}</div>
                  {/* Apply Button */}
                  <div className="mt-4">
                    <a href={job.link} className="inline-block px-6 py-3 text-white bg-blue-600 hover:bg-blue-700 rounded-full text-sm font-medium">
                      Apply for this job
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobsList;
