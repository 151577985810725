import { ArrowTopRightOnSquareIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";

const TermsAndConditions: React.FC = () => {
  const linkItems = [
    { text: "Introduction", icon: ChevronRightIcon, target: "introduction" },
    { text: "Who We Are and How to Contact Us", icon: ArrowTopRightOnSquareIcon, target: "who-we-are" },
    { text: "Glossary", icon: ChevronRightIcon, target: "glossary" },
    { text: "Getting Started", icon: ChevronRightIcon, target: "getting-started" },
    { text: "About the Card and E-Wallet", icon: ChevronRightIcon, target: "card-e-wallet" },
    { text: "Use of the Card and E-Wallet", icon: ChevronRightIcon, target: "use-of-card-e-wallet" },
    { text: "Withdrawing Funds", icon: ChevronRightIcon, target: "withdrawing-funds" },
    { text: "Fees and Costs", icon: ChevronRightIcon, target: "fees-and-costs" },
    { text: "Communicating with You", icon: ChevronRightIcon, target: "communication" },
    { text: "Keeping Your Card Safe", icon: ChevronRightIcon, target: "keeping-card-safe" },
    { text: "Unauthorised Transactions", icon: ChevronRightIcon, target: "unauthorised-transactions" },
    { text: "Our Right to Make Changes", icon: ChevronRightIcon, target: "right-to-change" },
    { text: "Closing Your Card and Ending Agreement", icon: ChevronRightIcon, target: "closing-account" },
    { text: "Redeeming Funds", icon: ChevronRightIcon, target: "redeeming-funds" },
    { text: "Liability", icon: ChevronRightIcon, target: "liability" },
    { text: "Law Governing This Agreement", icon: ChevronRightIcon, target: "law-governing" },
    { text: "Dispute Resolution", icon: ChevronRightIcon, target: "dispute-resolution" },
    { text: "Data Protection", icon: ChevronRightIcon, target: "data-protection" },
    { text: "Other Legal Terms", icon: ChevronRightIcon, target: "other-legal-terms" },
  ];

  return (
    <div className="flex flex-col items-center container pt-20">
      {/* Header Section */}
      <header className="w-full px-5">
        <h1 className="text-4xl font-extrabold text-gray-900">Terms and Conditions</h1>
        <p className="mt-4 text-lg text-gray-700">This notice was last updated on 2 January 2024.</p>
      </header>

      {/* Link Section */}
      <div className="w-full mt-8 bg-gray-100 rounded-lg p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {linkItems.map((item, index) => {
            const IconComponent = item.icon;
            return (
              <a key={index} href={`#${item.target}`} className="flex items-center justify-between bg-white p-4 rounded-lg shadow-md hover:bg-gray-50 transition">
                <span className="text-gray-900 font-medium">{item.text}</span>
                <IconComponent className="h-6 w-6" />
              </a>
            );
          })}
        </div>
      </div>

      {/* Sections */}
      <section id="introduction" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">1. Introduction</h3>
        <p className="text-gray-800">This Agreement contains the terms on which we provide the Services to you.</p>
        <p className="text-gray-800">
          Please read this Agreement in full before you start using the Services; it contains important information about us, the Services we will provide to you, the circumstances under which this Agreement may be changed or ended, what to do and
          how to access help from us in the event there is a problem as well as other relevant information.
        </p>
        <p className="text-gray-800">Please contact us via means available on the website if you wish to discuss.</p>
        <p className="text-gray-800">This Agreement refers to other documents that apply to the use of the Services as follows:</p>
        <ul className="list-disc pl-5 text-gray-800">
          <li>
            <a href="https://www.monavate.com/privacy-policy" className="text-blue-500" target="_blank" rel="noopener noreferrer">
              Monavate Privacy Policy
            </a>
          </li>
          <li>
            <a href="https://orenda.finance/privacy-policy" className="text-blue-500" target="_blank" rel="noopener noreferrer">
              Orenda Privacy Policy
            </a>
          </li>
        </ul>
        <p className="text-gray-800">These additional documents are not part of the Framework Contract.</p>
        <p className="text-gray-800">
          By starting to use the Services (including, for example, downloading the app, logging into your secure personal log in area on our website, loading your E-wallet with funds or otherwise using the Card to transact) you confirm that you
          accept and agree to this Agreement (including any updates to this Agreement and the other documents referred to above).
        </p>
      </section>

      <section id="who-we-are" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">2. Who we are and how to contact us</h3>
        <p className="text-gray-800">
          <strong>Our company information:</strong> Monavate Limited is a company incorporated under the laws of England and Wales with registered company number 12472532. Our registered address is The Officers Mess Business Centre Royston Road
          Duxford, Cambridge, CB22 4QH ("Monavate", “us”, “we”, “our”, as applicable in the context).
        </p>
        <p className="text-gray-800">
          <strong>We are authorised by the FCA:</strong>Monavate Limited is authorised by the Financial Conduct Authority (“FCA”) (registration number 901097) to issue electronic money and provide payment services.
        </p>
        <p className="text-gray-800">
          <strong>How to contact us:</strong>You can contact us:
        </p>
        <ul className="list-disc pl-5 text-gray-800">
          <li>By phone: 01223 626 580</li>
          <li>By email: info@monavate.com</li>
        </ul>
        <p className="text-gray-800">
          <strong>By writing to:</strong>
        </p>
        <ul className="list-disc pl-5 text-gray-800">
          <li>Monavate Cambridge</li>
          <li>
            The Officers' Mess
            <br />
            Royston Road
            <br />
            Duxford
            <br />
            Cambridge, CB22 4QH
            <br />
            United Kingdom
          </li>
        </ul>
        <p className="text-gray-800">
          <strong>By instant messaging / chat:</strong> When logged into your secure personal log in area of our website.
        </p>
      </section>

      <section id="glossary" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">3. Glossary</h3>
        <ul className="list-disc pl-5 text-gray-800">
          <p>
            <strong>Card</strong> means the payment card issued by us to you that is connected to your E-wallet and may be used to transact.
          </p>
          <p>
            <strong>E-wallet</strong> means an e-money account issued to you by us that you may load with funds and use to transact.
          </p>
          <p>
            <strong>Framework Contract</strong> means a contract for payment services which governs the future execution of individual and successive payment transactions and which may contain the obligation and conditions for setting up a payment
            account as defined in the UK Payment Services Regulations 2017.
          </p>
          <p>
            <strong>Orenda</strong> means Orenda Financial Services Limited which is a company registered in the United Kingdom with Company Number 1240498 and address St Martins House, 1 Gresham Street, London, England EC2V 7BX acting as programme
            manager for the Taupe Global Systems Ltd trading as Finvue programme.
          </p>
          <p>
            <strong>Taupe Global Systems Ltd trading as Finvue</strong> is a company registered in the United Kingdom with Company Number 14978273 and address Level 18, 40 Bank Street, Canary Wharf, London, E14 5NR, United Kingdom, acting as a
            payment programme.
          </p>
          <p>
            <strong>Funding Source</strong> means a payment instrument (such as a credit card, debit card or bank account) that you can link to your E-wallet by entering its credentials and subsequently use for Top-Ups from time to time.
          </p>
          <p>Services means the Card, E-wallet and other services provided by us to you in connection with the Card and the E-wallet.</p>
          <p>
            <strong>Supported</strong> Currencies means those foreign currencies that we support and can be loaded onto a E-wallet for spending.
          </p>
          <p>
            <strong>Top-Up</strong> means loading “topping up” your E-wallet with funds.
          </p>
        </ul>
      </section>

      {/* Remaining sections */}
      <section id="getting-started" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">4. Getting started</h3>
        <p className="text-gray-800">
          <strong>4.1. Corporate customer:</strong>By entering into this Agreement, you, the corporate customer, confirm that you are not a consumer, micro-enterprise or a charity within the meaning of the Payment Services Regulations 2017 (“PSR”)
          and agree:
        </p>
        <ul className="list-disc pl-5 text-gray-800">
          <li>Monavate Cambridge</li>
          <li>b. regulations 61(1), 67(3), 67(4), 75, 77, 79, 80, 83, 91, 92 and 94 of the PSR do not apply to this Agreement and a different time limit may be applied to this Agreement as to the timeline set out in regulation 74(1) of the PSR.</li>
        </ul>
        <p className="text-gray-800">
          <strong>4.2. Verification: </strong>We are required by law to verify certain details about you. We will not issue the Card or open an E-wallet until we have verified your identity and performed such other checks as we may in our discretion
          deem appropriate.
        </p>
        <p className="text-gray-800">
          <strong>4.3. The information you provide must be accurate: </strong>The information you provide to us must be accurate, complete and truthful at all information changes, then you must update the information we hold about you. We may require
          additional information from you from time to time and you must cooperate by providing such information upon request. If your personal times.
        </p>
        <p className="text-gray-800">
          <strong>4.4. Electronic checks with third parties:</strong>We may share information which we receive from you with other associated organisations or carry out checks on you electronically as is necessary for the purposes of providing the
          Services. When we perform electronic checks, we may disclose personal data about you to fraud prevention and credit reference agencies who may keep a record of that information. By applying for our Services, you agree to certain personal
          data about you being passed to such agencies for this purpose. You have the right of access to the personal records held by such fraud prevention agencies. We will supply the names and addresses of the agencies we use upon written request.
        </p>
        <p className="text-gray-800">
          <strong>4.5. You must comply with laws applicable to you at all times.</strong>You must comply with laws applicable to you at all times. This means when you must comply with all laws applicable to you during time in which you are applying
          with us for Services, throughout the term of this Agreement and for as long as we hold a balance of funds for you after this Agreement ends.
        </p>
      </section>

      <section id="card-e-wallet" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">5. About the Card and E-wallet</h3>
        <h4 className="text-xl my-4">5.1. Description</h4>
        <p className="text-gray-800">The Card and the E-wallet are issued by us – Movavate Limited. Please see our company’s details in Section 2 (Who we are and how to contact us).</p>
        <p className="text-gray-800">
          <strong>
            Card <br />
          </strong>
          The Card is a payment card that can be used for online and in-store purchases worldwide at merchants who accept Mastercard/Visa payment cards as a form of payment for goods and services. The Card is not a credit card or a charge card.
          Transactions made using the Card will be debited from the balance of funds held in the E-wallet.{" "}
        </p>
        <p className="text-gray-800">
          <strong>
            E-wallet <br />
          </strong>
          The E-wallet is an e-money account. You may load the E-wallet with e-money using different payment methods (see Section 5.3).{" "}
        </p>
        <p className="text-gray-800">No interest is payable to you on the balance of funds held in the E-wallet; nor does the balance of funds held in the E-wallet constitute a deposit.</p>
        <p className="text-gray-800">The E-wallet may be used to transact. In order to use the E-wallet to transact, you must first load funds onto the E-wallet (please see Section 5.3).</p>
        <p className="text-gray-800">Once loaded with funds, you may send funds from the E-wallet to another payment account.</p>
        <p className="text-gray-800">Each time you transact using the E-wallet or the Card, the balance of funds held in the E-wallet will be debited by the amount of the transaction plus any applicable fees.</p>
        <p className="text-gray-800">
          In addition to GBP, you may load your E-wallet with funds in different Supported Currencies. We do not support all foreign currencies. Please see the list on the website finvue.co.uk for those currencies that we do support.
        </p>
        <p className="text-gray-800">
          Please note that the funds held in your E-wallet are NOT protected by the Financial Services Compensation Scheme; however, we follow the requirements of the Electronic Money Regulations 2011 which are designed to ensure the safety of funds
          held on accounts such as this.
        </p>
        <h4 className="text-xl my-4">5.2. What happens in cases where there are insufficient funds in my E-wallet?</h4>
        <p className="text-gray-800">If you attempt to send money from the E-wallet or pay for a purchase using the Card and you have an insufficient balance of funds held in your E-wallet to make that payment, the payment will be declined.</p>
        <p className="text-gray-800">You must have a sufficient balance of funds held in your E-wallet to make a payment. The balance of funds held in your E-wallet must not be negative.</p>
        <p className="text-gray-800">
          If you attempt to make a payment using the E-wallet or Card in a particular currency and you do not hold a sufficient balance of that currency in the E-wallet or the payment otherwise anticipates a currency that we do not support, the
          payment will be automatically processed using a balance of another currency that you do hold in your E-wallet and a currency conversion rate will be applied (please see section 9.3 for currency conversion rates).
        </p>
        <p className="text-gray-800">If you do not hold a balance in another currency upon which to draw funds to cover a payment, your payment will be declined.</p>
        <h4 className="text-xl my-4">5.3. Loading funds into your E-wallet with Top-Up</h4>
        <p className="text-gray-800">
          <strong>5.3.1. </strong>You can load funds into your E-wallet by linking a Funding Source to your E-wallet. You can link a Funding Source to your E-wallet by entering the Funding Source’s credentials in the secure personal area of our
          website.
        </p>
        <p className="text-gray-800">
          <strong>5.3.2. </strong>You may link up to three debit or credit cards or one bank account to your E-wallet as a Funding Source. You must have no less than one valid Funding Source linked to your E-wallet at all times; payments made from
          the E-wallet may be declined if you do not have a valid Funding Source linked to the E-wallet. In order to Top-Up your E-wallet using your Funding Source, you must follow the applicable steps in the secure personal log in area of our
          website.
        </p>
        <p className="text-gray-800">
          <strong>5.3.3. </strong>When we receive a payment from your Funding Source, we may hold the funds for so long as we determine that there is no risk that the issuer of the Funding Source (i.e. your card-issuing or account-issuing bank) may
          reverse the transfer due to there being insufficient funds in your bank account.
        </p>
        <p className="text-gray-800">
          <strong>5.3.4. </strong>In order to ensure you receive good outcomes from your use of our Services, we may limit the Funding Sources available for a transaction. If we limit a Funding Source, we will alert you that there is a
          higher-than-normal level of risk associated with the payment (for example, a risk that the payment may be challenged for some reason). If we contact you for this reason, we are not asserting that you or the merchant who you seek to pay is
          acting in a dishonest or fraudulent manner. It means that we consider there may be a higher-than-normal risk associated with the payment and we are investigating to ensure you receive a good outcome, in as far as possible.
        </p>
      </section>
      <section id="use-of-card-e-wallet" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">6. Use of the E-wallet and the Card</h3>
        <h4 className="text-xl my-4">6.1. How to authorise a payment</h4>
        <p className="text-gray-800">You will need to authorise a transaction before we can charge it to your E-wallet. </p>
        <p className="text-gray-800">
          <strong>
            6.1.1. Authorising payments made using your E-wallet: <br />
          </strong>
          To authorise a payment to another payment account from your E-wallet, you will need to enter the IBAN or account number and sort code of the person you wish to pay (the payee). You must then follow the relevant steps displayed on the screen
          in the secure personal log in area of our website at the time of transacting in order to authorise the payment.
        </p>
        <p className="text-gray-800">
          <strong>
            6.1.2. Authorising payments made using your Card: <br />
          </strong>
          To authorise a payment made using the Card, you will need to use the Card or the Card number. You may need to take additional steps to authorise a transaction at the time you are paying, for example, you may need to enter details on a
          merchant’s online payment page, tap your Card at the point of sale in-store or enter your PIN on the key pad. Depending on the circumstances, you may be required to undergo some further security checks at the time such as providing
          biometric data (e.g. a fingerprint).{" "}
        </p>
        <p className="text-gray-800">
          If the Card, card number and PIN, biometric data or other security details have been used, we will assume you have authorised the transaction. You must therefore tell us immediately if you do not recognise a transaction that has been
          charged to your Card and is listed as a transaction in your secure personal log in area of our website.
        </p>
        <h4 className="text-xl my-4">
          <strong>6.1.3. </strong> You cannot cancel a transaction after it has been authorised.
        </h4>
        <h4 className="text-xl my-4">6.2. Situations where we may refuse to authorise a transaction</h4>
        <p className="text-gray-800">There are certain situations in which we may refuse to authorise a transaction and/or where we may suspend your use of your Card and/or the E-wallet. These situations are:</p>
        <ul className="list-disc pl-5 text-gray-800">
          <li>if legal or regulatory requirements prevent us from making the payment or mean that we need to carry out further checks;</li>
          <li>if you have infringed this Agreement in a way that we reasonably believe justifies our refusing or delaying your payment;</li>
          <li>if we believe that processing your transaction would break this Agreement or that you have not provided all the information we need to make the payment properly;</li>
          <li>Monavate Cambridge</li>
          <li>if the amount is over, or would take you over, any limit that applies to your E-wallet and/or Card. See limits here finvue.co.uk</li>
          <li>if there are not enough funds loaded into your E-wallet to make the payment and cover any fees;</li>
          <li>if a bankruptcy order is made against you or you have entered into an individual voluntary arrangement with your creditors;</li>
          <li>if, even after doing everything reasonably possible, we will not be able to make the payment on time;</li>
          <li>if a third party prevents us from making the payment (for example, a card scheme);</li>
          <li>if you owe us money;</li>
          <li>if we have asked you for important information we reasonably need and you have not given us that information; or</li>
          <li>if we have suspended your account.</li>
        </ul>
        <h4 className="text-xl my-4">6.3. Time we will receive the payment order</h4>
        <p className="text-gray-800">If your payment order is received by us after 4pm on a business day or not on a business day,your payment order will be deemed received on the following business day.</p>
        <h4 className="text-xl my-4">6.4. Execution times</h4>
        <p className="text-gray-800">
          <strong>
            6.4.1. <br />
          </strong>
          Within the EEA and UK, we will execute any transaction:{" "}
        </p>
        <p className="text-gray-800">
          <strong>
            a <br />
          </strong>
          in euro;{" "}
        </p>
        <p className="text-gray-800">
          <strong>
            b <br />
          </strong>
          executed wholly in the UK in sterling; or{" "}
        </p>
        <p className="text-gray-800">
          <strong>
            c <br />
          </strong>
          involving only one currency conversion between the euro and sterling, provided that:
        </p>
        <p className="text-gray-800">
          <strong>
            i <br />
          </strong>
          the required currency conversion is carried out in the UK; and
        </p>
        <p className="text-gray-800">
          <strong>
            ii <br />
          </strong>
          in the case of cross-border transactions, the cross-border transfer takes place in euro, by transferring the amount of the transaction to the payment service provider of the payee by the end of the next business day following the receipt of
          the payment order.
        </p>

        <p className="text-gray-800">
          <strong>
            6.4.2. <br />
          </strong>
          Any other transactions within the UK will be executed no later than four business days following the receipt of the payment order.{" "}
        </p>
        <p className="text-gray-800">
          <strong>
            6.4.3. <br />
          </strong>{" "}
          If the payment service provider of the merchant is located outside the EEA, we will execute the transaction as soon as possible.{" "}
        </p>

        <h4 className="text-xl my-4">6.5. Limits placed on the E-wallet</h4>
        <p className="text-gray-800">
          <strong>
            6.4.1. <br />
          </strong>
          There may be limits on the amount of funds you can load in to the E-wallet. We may also place limits on using the E-wallet and/or the Card for payments above a certain amount, from time to time. For further information relating to the
          limits placed on the E-wallet and/or the Card, you can view our website finvue.co.uk.{" "}
        </p>

        <h4 className="text-xl my-4">6.6. Accounting for service charges or tips when using the Card</h4>
        <p className="text-gray-800">
          When using your Card at certain merchants (such as, for example, hotels or car rentals), an additional amount (typically 10%-20%) may be added to anticipated service charges or tips, temporarily reducing the balance of funds held on the
          E-wallet. This is to ensure there are sufficient funds available to cover the final cost of the transaction and to reduce the risk of a negative balance arising on the E-wallet. If your actual service charge or tip is less than the
          additional amount added, it may take up to seven (7) days from the date of the transaction before the difference is available to spend. Only the actual amount of the final bill agreed between you and the merchant will be deducted from the
          funds held on the E-wallet.
        </p>

        <h4 className="text-xl my-4">6.7. Shortfall</h4>
        <p className="text-gray-800">
          In the unlikely event, a transaction is completed when there are insufficient funds in the E-wallet for that transaction (a "Shortfall"), the Shortfall shall be reimbursed by you unless it is due to an error on the part of the Merchant
          where the Card was presented, in this circumstance we may seek the Shortfall from the Merchant.
        </p>
        <p className="text-gray-800">
          You agree that once we make this Shortfall known to you, we may charge you for the Shortfall amount and deduct the amount from the balance of funds loaded into the E-wallet or from your linked Funding Source. We may also charge the amount
          of the Shortfall from any other Cards that you hold with us, or any other payment method which you may designate at that time, or against any funds which you may subsequently load into the E-wallet. Until we are reimbursed the Shortfall
          amount, we may suspend the E-wallet and the Card. In addition, we reserve the right to charge you an administration fee for each transaction that you make using the E-wallet and/or Card that results in a Shortfall or increases the Shortfall
          amount on the E-wallet.
        </p>
        <h4 className="text-xl my-4">7. The Card will expire</h4>
        <p className="text-gray-800">
          You may only use your Card up until the expiry date which is printed on the front of the physical Card. We will aim to send you a new Card prior to your Card expiring; we may ask you to confirm some details prior to our sending you a new
          Card.{" "}
        </p>
        <p className="text-gray-800">Fees may apply in certain cases where we send you a new Card. Please see the fees set out on our website finvue.co.uk.</p>
      </section>
      <section id="withdrawing-funds" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">8. Withdrawing funds held in your E-wallet</h3>

        <h4 className="text-xl my-4">8.1. How to withdraw the funds held in your E-wallet</h4>
        <p className="text-gray-800">
          You may withdraw funds held in your E-wallet by first logging into the secure personal log in area of our website and following the relevant prompts on the screen at the time of transacting. You may only withdraw the balance of the funds
          held in the E-wallet at any point in time minus the amount of any pending transactions.
        </p>

        <h4 className="text-xl my-4">8.2. Fees will apply to withdrawing funds</h4>
        <p className="text-gray-800">You may be charged a fee for withdrawing funds. Please see the fees page on our website finvue.co.uk.</p>

        <h4 className="text-xl my-4">8.3. Withdrawing all funds will not mean that your E-wallet is closed</h4>
        <p className="text-gray-800">
          Withdrawing all of your funds from your E-wallet does not automatically close the E-wallet, the Card or otherwise end this Agreement. If you would like to end this Agreement, you must follow the steps in section 16 (closing your E-wallet
          and ending this Agreement).
        </p>
      </section>
      <section id="fees-and-costs" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">9. How much will you pay</h3>
        <p className="text-gray-800">
          <strong>9.1. Fees: </strong>You must pay the fees in connection with your use of the Services. We will not process your payment until we have received the relevant fees from you. You can see all fees applicable to you on our website
          <a href="https://finvue.co.uk" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://finvue.co.uk
          </a>
        </p>
        <p className="text-gray-800">
          <strong>9.2. Interest: </strong>The funds loaded on to your Card will not earn any interest.
        </p>
        <p className="text-gray-800">
          <strong>9.3. Exchange rates: </strong>You may hold balances of funds in different currencies in your E-wallet. If you are making a payment or withdrawing funds in a currency you hold in the If you make a payment or E-wallet, withdrawal
          funds in a currency you do not hold in your E-wallet, we will convert the amount applying the relevant rate and a fee to the balance or currency that you do hold.
        </p>
        <p className="text-gray-800">
          For transactions made using the Card, we will use the relevant exchange rate published by Mastercard or Visa
          <a href="https://www.visa.co.uk/support/consumer/travel-support/exchange-rate-calculator.html" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://www.visa.co.uk/support/consumer/travel-support/exchange-rate-calculator.html
          </a>
        </p>
        <p className="text-gray-800">
          For transactions made using the Card, we will use the relevant exchange rate published by Mastercard or Visa
          <a href="https://www.visa.co.uk/support/consumer/travel-support/exchange-rate-calculator.html" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://www.visa.co.uk/support/consumer/travel-support/exchange-rate-calculator.html
          </a>
        </p>
        <p className="text-gray-800">
          We will also apply our margin which is available on the fees pages of our website finvue.co.uk. Any change in the relevant exchange rate will be applied immediately and without notice to you. We may also change our exchange rate provider at
          our discretion, without notice to you.
        </p>
      </section>

      <section id="communication" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">10. Communicating with you</h3>
        <p className="text-gray-800">
          <strong>10.1. How we will contact you: </strong>We may contact you by telephone, letter, SMS or email using the details that you have provided to us in your secure personal log in area of our website. You must keep your contact details in
          the secure personal log in area up-to-date at all times.
        </p>
        <p className="text-gray-800">
          <strong>10.2. Information: </strong>Where we are required to provide information to you on a durable medium, we will either send you a notification in your secure personal log in area of our website pointing you to information in the
          personal log in area in a way that enables you to keep that information, unchanged, and refer to it in the future, such as pdf. Please keep copies of information provided by us to you. If you would like us to provide information in a
          different manner than agreed, then we may charge you a reasonable administration fee to cover our costs.
        </p>
        <p className="text-gray-800">
          <strong>10.3. Language: </strong>We will communicate with you in English.
        </p>
        <p className="text-gray-800">
          <strong>10.4. Your right to receive a copy of this Agreement: </strong>A copy of this Agreement is always available in a form to download on our website. Please visit finvue.co.uk. Alternatively, please contact us at support@finvue.co.uk if
          you need assistance in obtaining a copy.
        </p>
      </section>

      <section id="keeping-card-safe" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">11. Keeping your card safe</h3>
        <p className="text-gray-800">
          <strong>11.1. Steps you must take to keep your E-wallet and Card safe</strong>
        </p>
        <p className="text-gray-800">
          <strong>You must take adequate measures and use best efforts to prevent unauthorized access/use of the Services: </strong>To help you keep your E-wallet and Card secure, you are required to:
        </p>
        <ul className="list-disc pl-5 text-gray-800">
          <li>
            keep your Card, Card number, CCV, login codes and other security features used to access the E-wallet strictly to yourself; do not share your security credentials to access the E-wallet with any other person or otherwise allow another
            person to use the Services;
          </li>
          <li>not permit any other person to use your E-wallet and/or Card;</li>
          <li>make sure all your devices are properly protected (set at least one form of access protection, for example a login code);</li>
          <li>keep the operating systems of your devices clean (no illegal software) and up-to-date;</li>
          <li>frequently review the list of transactions in your E-wallet to confirm they are as expected as per your spending;</li>
          <li>inform yourself about common scams, such as phishing;</li>
          <li>always immediately report irregularities by contacting us at support@finvue.co.uk and follow our instructions.</li>
        </ul>
        <p className="text-gray-800">
          <strong>11.2. How to notify us if there is a problem</strong>
        </p>
        <p className="text-gray-800">
          If you lose the security credentials used to access your E-wallet or your Card details or if they are stolen, or you suspect that the E-wallet and/or Card has been used by someone other than you, you must tell us immediately by contacting
          us on our contact details available here finvue.co.uk (support@finvue.co.uk), so we can block your Card and/ or access to your E-wallet . If possible, you must also log into your secure personal log in area of our website and suspend use of
          your Card.
        </p>
        <p className="text-gray-800">Please note that we may require you to provide some information in relation to your security credentials and/ or Card being lost, theft or misuse in writing. You must assist us with any related enquiries.</p>
        <p className="text-gray-800">
          <strong>11.3. How we will contact you if there is a problem</strong>
        </p>
        <p className="text-gray-800">
          In the event we suspect there is an issue with the E-wallet and/or Card (for example, such as fraud or security threats) we will contact you, in the first instance, by phone. If we cannot get through to you by phone, we will send you an
          email.{" "}
        </p>
        <p className="text-gray-800">For this reason, you must maintain a current phone number and email address in your secure personal log in area of our website.</p>
      </section>

      <section id="unauthorised-transactions" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">12. Unauthorised or incorrectly executed transactions</h3>
        <p className="text-gray-800">In case of an unauthorised payment or incorrectly executed transaction, you may request a refund. Refunds will not be issued if:</p>

        <p className="text-gray-800">
          <strong>12.1.</strong>
        </p>
        <p className="text-gray-800">if you have acted fraudulently, in which case we will not refund you in any circumstances;</p>

        <p className="text-gray-800">
          <strong>12.2.</strong>
        </p>
        <p className="text-gray-800">
          f the payment transaction was unauthorised but you have with intent or gross negligence compromised the security of your Card or E-wallet or otherwise failed to comply with your obligations to use your Card or E-wallet on the terms of this
          Agreement. In such a case you shall be solely liable for all losses. We will treat any payment instruction given using your Card and/or E-wallet as evidence that you authorised the transaction or did not otherwise keep your security
          credentials safe; or
        </p>

        <p className="text-gray-800">
          <strong>12.3.</strong>
        </p>
        <p className="text-gray-800">If you do not let us know about the unauthorised or incorrectly completed transaction without undue delay, and in any event, no later than 1 month from the date of the payment transaction.</p>

        <p className="text-gray-800">
          <strong>12.4.</strong>
        </p>
        <p className="text-gray-800">
          We may require you to provide us with information relating to the transaction and/ or your claim for a refund under this section. We may carry out an investigation prior to issuing you with any refund and you agree to cooperate with our
          investigation.
        </p>
      </section>

      <section id="right-to-change" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">13. Our right to make changes to the Agreement</h3>
        <p className="text-gray-800">
          In case of an unauthorised payment or where we have incorrectly executed a transaction, upon your request, we will refund to you the amount of the transaction including all fees that we have charged for that particular transaction. You will
          not be entitled to a refund under this section:
        </p>
      </section>

      <section id="closing-account" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Closing Your Card and Ending Agreement</h3>
        <p className="text-gray-800">We may, at our discretion, amend the Agreement at any time (including amendments and additions to our fees) without notice to you.</p>
      </section>

      <section id="redeeming-funds" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">14. Our right to suspend access to the Services and/or close the Card and E-wallet</h3>
        <p className="text-gray-800">We may close your Card and E-wallet or otherwise suspend access to the Services immediately in the following circumstances:</p>
        <ul className="list-disc pl-5 text-gray-800">
          <li>if we consider, acting reasonably, that you are behaving fraudulently or otherwise criminally;</li>
          <li>if you have not given us any information we have requested from you, or we have good reason to believe that information you have provided is incorrect or not true;</li>
          <li>if you have broken this Agreement in a serious or persistent way and you have refrained from putting the matter right within a reasonable time of us asking you to do so;</li>
          <li>if we have good reason to believe that you continuing to use your Card and/or E-wallet could damage our reputation or goodwill;</li>
          <li>if we have asked you to repay money you owe us and you have not done so within a reasonable period of time;</li>
          <li>if you have been declared bankrupt; or</li>
          <li>if we have to do so under any law, regulation, court order or the FCA or FOS’s instructions.</li>
        </ul>
      </section>

      <section id="liability" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">15. Closing your Card and ending this Agreement</h3>
        <p className="text-gray-800">
          <strong>15.1. </strong>You may close your Card and E-Wallet and end this Agreement by providing us with one month’s notice in writing and paying the relevant fees.
        </p>
        <p className="text-gray-800">
          <strong>15.2. </strong>You must withdraw your funds from your E-wallet within a reasonable timeframe following notifying us of your intention to close your E-wallet by following the steps as to how to withdraw the funds held in your
          E-wallet as set out in section 8. After a certain period of time, you may no longer have access to your secure personal log in area of our website in order to follow the steps to withdraw the funds remaining in your E-wallet after closure.
          You may still, however, withdraw this money for up to six years following the date this Agreement ends (see Section 16 on how to redeem your funds).
        </p>
      </section>

      <section id="law-governing" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">16. Redeeming the funds held in your E-wallet</h3>
        <p className="text-gray-800">
          <strong>16.1. </strong>If you have funds held in your E-wallet after this Agreement has ended, you may withdraw these funds for up to six years following the date the Agreement ends.
        </p>
        <p className="text-gray-800">
          <strong>16.2. </strong>If there are still funds held in your E-wallet 12 months following the end of this Agreement, we will start deducting a fee from your account that will cover our costs in order to maintain this balance for you until
          such time as you redeem your funds.
        </p>
        <p className="text-gray-800">
          <strong>16.3. </strong>If you wish to redeem your funds after this Agreement has ended and you no longer have access to your secure personal area of our website, please contact us support@finvue.co.uk so we can help.
        </p>
        <p className="text-gray-800">
          <strong>16.4. </strong>If you request to redeem your funds after this Agreement ends, we may need to carry out some checks prior to sending the funds to you. We may therefore ask you to provide some information to us at this time, such as
          identity documents and so on.
        </p>
        <p className="text-gray-800">
          <strong>16.5. </strong>You must pay the relevant fees for redeeming your funds.
        </p>
      </section>

      <section id="dispute-resolution" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">17. Liability</h3>
        <p className="text-gray-800">
          <strong>17.1. </strong>We will not be liable for:
        </p>
        <p className="text-gray-800">
          <strong>17.1.1. </strong>any fault or failure relating to the use of the Services that is a result of abnormal and unforeseeable circumstances beyond our control which would have been unavoidable despite all our efforts to the contrary,
          including but not limited to, a fault in or failure of data processing systems;
        </p>
        <p className="text-gray-800">
          <strong>17.1.2. </strong>the goods or services that you purchase with using the Services (unless otherwise agreed);
        </p>
        <p className="text-gray-800">
          <strong>17.1.3. </strong>any loss of profits, loss of business, or any indirect, consequential, special or punitive losses;
        </p>
        <p className="text-gray-800">
          <strong>17.1.4. </strong>a merchant refusing to honour a transaction or refusing a payment; or
        </p>
        <p className="text-gray-800">
          <strong>17.1.5. </strong>any acts or omissions that are a consequence of our compliance with any UK law or any other laws to which we are subject.
        </p>
        <p className="text-gray-800">
          <strong>17.2. </strong>In any event, our liability will be limited to the balance of funds held in your E-wallet at the time that the event occurs.
        </p>
        <p className="text-gray-800">
          <strong>17.3. </strong>Nothing in this Agreement shall exclude or limit any regulatory responsibilities we have which we are not permitted to exclude or limit, or our liability for death or personal injury.
        </p>
        <p className="text-gray-800">
          <strong>17.4. </strong>If you have used the Services or allowed the Services we provide to you to be used fraudulently, in a manner that does not comply with this Agreement, for illegal purposes, or if you have allowed access to the
          Services to be compromised due to your gross negligence, you will be held responsible for the use and misuse of the Services.
        </p>
      </section>
      <section id="law-governing" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">18. Law governing this Agreement and jurisdiction</h3>
        <p className="text-gray-800">The law governing this Agreement is the law of England and Wales. The courts of England and Wales have jurisdiction to adjudicate any dispute between you and us in connection with this Agreement.</p>
      </section>
      <section id="dispute-resolution" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">19. Dispute resolution</h3>
        <p className="text-gray-800">If you are not satisfied with the service you receive from us you should contact us support@finvue.co.uk. Correspondence may be monitored or recorded. </p>
      </section>

      <section id="data-protection" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">20. Data protection</h3>
        <p className="text-gray-800">
          We need to collect information about you to provide you with the Services under this Agreement. For information on how we use your personal data, please refer to our privacy notice
          <a href="https://orenda.finance/privacy-policy" className="text-blue-500" target="_blank" rel="noopener noreferrer">
            https://orenda.finance/privacy-policy
          </a>
          .
        </p>
        <p className="text-gray-800">
          By entering into this Agreement, you acknowledge and agree that we may collect, process and store your personal information for the purposes of providing our Services to you. This does not affect any rights and obligations you or we have
          under data protection law.
        </p>
      </section>

      <section id="other-legal-terms" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">21. Other legal terms</h3>
        <p className="text-gray-800">
          <strong>
            21.1. We may transfer this Agreement to someone else <br />
          </strong>
          We may assign or otherwise transfer any of our rights and obligations under the Agreement, without your prior consent, to any other person or business, subject to such party continuing the obligations in the Agreement to you.
        </p>
        <p className="text-gray-800">
          <strong>
            21.2. Third parties <br />
          </strong>
          This Agreement is between you and us only. Except for any party to whom we transfer this Agreement (under section 21.1), nothing in the Agreement gives any third party any benefit or right (including any enforcement right).
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
