import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Button from "components/Button/Button";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";
import ContactOffice from "components/ContactOffice/ContactOffice";
import FaqSection from "components/FaqSections/FaqSection";
import FaqSectionDark from "components/FaqSections/FaqSectionDark";
import GlobalStats from "components/FinvueComponents/ExperiencesSections/GlobalStats";
import FinVuePlans from "components/FinvueComponents/FinVuePlans/FinVuePlans";
import PricingPlans from "components/FinvueComponents/PricingSection/PricingPlans";
import Heading from "components/Heading/Heading";
import SectionHero from "components/SectionHero/SectionHero";
import SectionTextImage from "components/SectionTextImages/SectionTextImage";
import rightImg from "images/about-hero-right.png";
import featureRight from "images/finvue/availablemobile.png";
import plansBg from "images/finvue/plans/cards-plans.png";
import oneFeatureCenter from "images/one-section-cards-center.png";
import oneFeatureRight from "images/one-section-debit-card-right.png";
import React from "react";
import SectionStatistic from "../about/SectionStatistic";
import StatCard from "../about/StatCard";
import FeatureCards from "./FeatureCards";

const statistics = [
  { value: "60", label: "Global Customers" },
  { value: "150", label: "Payment Locations" },
  { value: "3", label: "Offices" },
];

const PagePlans = () => {
  return (
    <div className={`nc-PagePlans relative bg-zinc-900 overflow-hidden`}>
      {/* ======== BG GLASS ======== */}

      <div className="bg-zinc-900 ">
        <SectionHero
          centerImg={plansBg}
          className="py-2"
          topHeading={<span className="text-white">Explore Business account pricing</span>}
          heading={<span className="text-3xl lg:text-6xl text-slate-50 font-black">Pick a plan tailored to your business needs</span>}
          btnTextPrimary="View Plans"
          btnPrimaryUrl="#plansLink"
          subHeading="Find the plan to fuel yours — enjoy simple pricing, and switch easily as you scale."
          subHeadingColor="text-slate-200"
        />
      </div>
      <div className="relative" id="plansLink">
        <Heading className="bg-zinc-900" isCenter desc="">
          <h3 className="text-white">Fnd your perfect fit</h3>
        </Heading>
        <PricingPlans />
      </div>
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div className="relative py-16">
          <FaqSectionDark dataIds={[1, 2, 3, 4]} />
        </div>

        <section>
          <Heading isCenter desc="Open an account under your registered business name. Keep costs down and productivity high.   ">
            <h3 className="text-white">Doing business smarter starts here</h3>
          </Heading>
          <div className={`flex space-x-4 justify-center`}>
            <ButtonPrimary href="/sign-up">Get Started</ButtonPrimary>
            <ButtonSecondary href="/contact">Contact Sales</ButtonSecondary>
          </div>
        </section>

        <ContactOffice />
      </div>
      <section>
        <FinVuePlans />
      </section>
    </div>
  );
};

export default PagePlans;
