import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Button from "components/Button/Button";
import ContactOffice from "components/ContactOffice/ContactOffice";
import FaqSection from "components/FaqSections/FaqSection";
import GlobalStats from "components/FinvueComponents/ExperiencesSections/GlobalStats";
import FinVuePlans from "components/FinvueComponents/FinVuePlans/FinVuePlans";
import Heading from "components/Heading/Heading";
import SectionHero from "components/SectionHero/SectionHero";

import JobsList from "components/JobsSections/JobsList";
import SectionTextImage from "components/SectionTextImages/SectionTextImage";
import rightImg from "images/about-hero-right.png";
import featureRight from "images/finvue/availablemobile.png";
import careersBg from "images/finvue/careers-bg.jpg";
import oneFeatureCenter from "images/one-section-cards-center.png";
import oneFeatureRight from "images/one-section-debit-card-right.png";
import React from "react";
import SectionStatistic from "../about/SectionStatistic";
import StatCard from "../about/StatCard";
import FeatureCards from "./FeatureCards";

const statistics = [
  { value: "60", label: "Global Customers" },
  { value: "150", label: "Payment Locations" },
  { value: "3", label: "Offices" },
];

const PageAbout = () => {
  return (
    <div className={`nc-PageCareers relative overflow-hidden `}>
      {/* Add a full-width 40px height bg-slate-800 bar */}
      <div className="w-full h-20 bg-slate-900"></div>

      <div className="">
        <SectionHero
          centerImg={careersBg}
          className=""
          centerImgClasses="object-contain object-top lg:object-top"
          heading={
            <div className="text-5xl lg:text-7xl text-slate-950 font-black">
              Calling all
              <span className="text-blue-600 block">innovators</span>
            </div>
          }
          btnTextPrimary="Getting started"
          btnPrimaryUrl="/getting-started"
          subHeading="Join us and empower the next generation of business."
          subHeadingColor="text-slate-950"
        />
      </div>
      <BgGlassmorphism />
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <section>
          <Heading isCenter desc="Experience a dynamic and inclusive workplace where your ideas drive innovation and growth.">
            <h3 className="text-slate-950 font-bold text-3xl md:text-5xl">
              Working at <span className="text-blue-700">FinVue</span>
            </h3>
          </Heading>
          <div className="flex justify-center">
            <Button sizeClass="py-3 px-4 sm:px-6" href="##" pattern="white">
              Join the journey
            </Button>
          </div>
        </section>
        <FeatureCards />
        <Heading isCenter desc="">
          <h3 className="text-slate-950 text-3xl md:text-5xl font-bold">Experience why</h3>
          <h3 className="text-blue-700 text-3xl md:text-5xl font-bold mt-2">FinVue stands out</h3>
        </Heading>
        <GlobalStats />
        <div className="relative">
          <JobsList dataIds={[1, 2, 3, 4]} />
        </div>

        <ContactOffice />
      </div>
      <section>
        <FinVuePlans />
      </section>
    </div>
  );
};

export default PageAbout;
