import React from "react";
import { DropdownProps } from "../../data/types";

export const TabDropdown: React.FC<DropdownProps> = ({ items, selectedTab, onSelect, isOpen, onToggle }) => {
  const selectedLabel = items.find((item) => item.id === selectedTab)?.label;

  return (
    <div className="relative w-full">
      <button onClick={onToggle} className="w-full px-4 py-2 text-left bg-white md:bg-gray-200 rounded text-sm font-bold uppercase">
        {selectedLabel || "Select"}
      </button>
      {isOpen && (
        <ul className="absolute left-0 w-full bg-white border rounded shadow-md">
          {items.map((item) => (
            <li key={item.id}>
              <button
                onClick={() => {
                  onSelect(item.id);
                  onToggle();
                }}
                className="w-full px-4 py-2 text-left hover:bg-gray-100 text-sm font-bold uppercase"
              >
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
