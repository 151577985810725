import officeImg from "images/finvue/shared-offices.webp";
import React from "react";

interface FeatureCardProps {
  number: number;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ number, title, description }) => {
  return (
    <section className="flex flex-col grow items-start p-8 w-full font-medium tracking-tight rounded-3xl bg-zinc-100 text-zinc-900 max-md:px-5 max-md:mt-4 max-md:max-w-full">
      <div className="flex relative flex-col px-2 py-2 w-6 text-base leading-3 whitespace-nowrap aspect-square">
        <span className="relative flex justify-center items-center rounded-full z-10 bg-slate-200 text-slate-900 text-xs font-bold w-7 h-7">{number}</span>
      </div>
      <h2 className="mt-4 text-xl md:text-2xl">{title}</h2>
      <p className="mt-6 text-base text-slate-500 max-md:mt-10">{description}</p>
    </section>
  );
};

export default FeatureCard;
