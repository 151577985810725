import React from "react";

const CallToAction: React.FC = () => {
  return (
    <div className="flex flex-col items-start mt-32 ml-3 max-w-full font-medium w-[553px] max-md:mt-10">
      <h2 className="text-2xl md:text-3xl tracking-tight leading-none text-zinc-400">What are you waiting for?</h2>
      <p className="self-stretch mt-5 md:text-5xl tracking-tight leading-none text-white max-md:max-w-full max-md:text-3xl">Change the way you do business</p>
      <a href="/sign-up" className="px-7 py-5 mt-10 text-sm md:text-xl leading-none text-center bg-white rounded-full text-zinc-900 max-md:px-5">
        Register now
      </a>
    </div>
  );
};

export default CallToAction;
