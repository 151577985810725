import { ArrowTopRightOnSquareIcon, ArrowUturnLeftIcon, ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

const CookiePolicy: React.FC = () => {
  const linkItems = [
    { text: "Introduction", icon: ChevronRightIcon, target: "introduction" },
    { text: "What Are Cookies?", icon: ArrowTopRightOnSquareIcon, target: "what-are-cookies" },
    {
      text: "Types of Cookies We Use",
      icon: ChevronRightIcon,
      target: "types-of-cookies",
    },
    { text: "How We Use Cookies", icon: ArrowUturnLeftIcon, target: "how-we-use-cookies" },
    {
      text: "Managing Cookies",
      icon: ChevronRightIcon,
      target: "managing-cookies",
    },
    { text: "Third-Party Cookies", icon: XMarkIcon, target: "third-party-cookies" },

    { text: "Changes to This Cookie Policy", icon: ArrowTopRightOnSquareIcon, target: "changes-to-this-cookie-policy" },
    { text: "Contact Us", icon: ChevronRightIcon, target: "contact-us" },
  ];

  return (
    <div className="flex flex-col items-center container pt-20">
      {/* Header Section */}
      <header className="w-full px-5">
        <h1 className="text-4xl font-extrabold text-gray-900">Privacy Notice</h1>
        <p className="mt-4 text-lg text-gray-700">This notice was last updated on 1 December 2024. </p>
      </header>

      {/* Main Content Section */}
      <section className="w-full px-5 mt-8 p-6">
        <p className="text-gray-800">If you have any questions about how we use your personal data, you can get in touch by one of the methods set out in the Contact us section.</p>
        <p className="mt-4 text-gray-800">If you have any questions about how we use your personal data, you can get in touch by one of the methods set out in the Contact us section.</p>
        <p className="mt-4 text-gray-800">
          This privacy policy applies between you, the User of this Website and the Orenda Financial Services Limited (Orenda), the collector and processor of information provided by you through this website. Orenda takes the privacy of your
          information very seriously. This privacy policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the Website. Please read this privacy policy carefully.
        </p>
        <p className="mt-4 text-gray-800">You can scroll down to read the entire notice, or you can jump to the section you are interested in by clicking on one of the headings below:</p>
      </section>

      {/* Link Section */}
      <div className="w-full mt-8 bg-gray-100 rounded-lg p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {linkItems.map((item, index) => {
            const IconComponent = item.icon; // Get the icon component dynamically
            return (
              <a
                key={index}
                href={`#${item.target}`} // Anchor link to the section
                className="flex items-center justify-between bg-white p-4 rounded-lg shadow-md hover:bg-gray-50 transition"
              >
                <span className="text-gray-900 font-medium">{item.text}</span>
                <IconComponent className="h-6 w-6" /> {/* Render the icon dynamically */}
              </a>
            );
          })}
        </div>
      </div>

      {/* Sections with IDs */}
      <section className="w-full px-5 mt-8 scroll-mt-20">
        <h2 className="text-4xl my-4">Definitions and interpretation</h2>
      </section>
      <section id="introduction" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Introduction</h3>
        <p className="text-gray-800">This Cookie Policy explains how we use cookies and similar technologies on our website. By using our website, you agree to the use of cookies as described in this policy.</p>
      </section>
      <section id="what-are-cookies" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">What Are Cookies? </h3>
        <p className="text-gray-800">Cookies are small text files that are placed on your device by websites you visit. They are widely used to make websites work, or work more efficiently, as well as to provide information to the site owners.</p>
      </section>
      <section id="types-of-cookies" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Types of Cookies We Use </h3>
        <p className="text-gray-800">We use the following types of cookies on our website: </p>
        <p className="text-gray-800">
          <strong>Strictly Necessary Cookies:</strong> These cookies are essential for the operation of our website. They enable you to navigate the site and use its features, such as accessing secure areas.
        </p>
        <p className="text-gray-800">
          <strong>Performance Cookies:</strong> These cookies collect information about how visitors use our website. They help us understand which pages are most and least popular and see how visitors move around the site. This information is used
          to improve the website’s performance and user experience.
        </p>
        <p className="text-gray-800">
          <strong>Functional Cookies:</strong> These cookies allow our website to remember choices you make (such as your username, language, or region) and provide enhanced, more personal features. They may also be used to provide services you have
          requested, such as watching a video or commenting on a blog.
        </p>

        <p className="text-gray-800">
          <strong>Targeting Cookies:</strong> These cookies are used to deliver adverts more relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and help measure the effectiveness of the
          advertising campaigns. These cookies remember that you have visited a website and this information is shared with other organisations such as advertisers.
        </p>
      </section>
      <section id="how-we-use-cookies" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">How We Use Cookies </h3>
        <p className="text-gray-800">Finvue uses cookies to:</p>
        <ul className="list-disc pl-5 text-gray-800">
          <li>Ensure our website functions properly.</li>
          <li>Analyse how our website is used and improve its performance and user experience.</li>
          <li>Remember your preferences and settings.</li>
          <li>Deliver personalised content and advertisements.</li>
        </ul>
      </section>
      <section id="managing-cookies" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4"> Managing Cookies </h3>
        <p className="text-gray-800">You can manage cookies through your web browser settings. Most browsers allow you to:</p>
        <ul className="list-disc pl-5 text-gray-800">
          <li>See what cookies you have and delete them on an individual basis.</li>
          <li>Block third-party cookies.</li>
          <li>Block cookies from particular sites.</li>
          <li>Block all cookies from being set.</li>
          <li>Delete all cookies when you close your browser.</li>
        </ul>
        <p className="text-gray-800">Please note that if you choose to block or delete cookies, some features of our website may not work correctly or at all.</p>
      </section>
      <section id="third-party-cookies" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Third-Party Cookies </h3>
        <p className="text-gray-800">In addition to our own cookies, we may also use various third-party cookies to report usage statistics of the website and deliver advertisements on and through the website.</p>
      </section>
      <section id="changes-to-this-cookie-policy" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4"> Changes to This Cookie Policy</h3>
        <p className="text-gray-800">We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on our website. You are advised to review this Cookie Policy periodically for any changes.</p>
      </section>
      <section id="contact-us" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Contact Us</h3>
        <p className="text-gray-800">If you have any questions about our use of cookies or other technologies, please email us at info@finvue.co.uk</p>
      </section>
    </div>
  );
};

export default CookiePolicy;
