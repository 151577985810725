import React from "react";
import FeatureRow from "./FeatureRow";

interface FeatureGroupProps {
  title: string;
  items: {
    feature: string;
    values: string[];
  }[];
}

const FeatureGroup: React.FC<FeatureGroupProps> = ({ title, items }) => {
  return (
    <div className="mt-6">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      {items.map((item, index) => (
        <FeatureRow key={index} {...item} />
      ))}
    </div>
  );
};

export default FeatureGroup;
