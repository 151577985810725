import React from "react";

interface PlanCardProps {
  title: string;
  description: string;
  iconSrc: string;
}

const PlanCard: React.FC<PlanCardProps> = ({ title, description, iconSrc }) => {
  return (
    <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
      <a href="/plans" className="flex relative flex-col grow items-start px-8 py-7 w-full rounded-3xl bg-neutral-100 max-md:px-5 max-md:mt-6 max-md:max-w-full hover:bg-slate-200">
        <h2 className="text-xl md:text-3xl font-medium tracking-tight leading-7 text-zinc-900">{title}</h2>
        <p className="mt-2 md:mt-7 text-sm md:text-lg  text-gray-600 max-md:max-w-full">{description}</p>
        <img loading="lazy" src={iconSrc} alt="" className="absolute bottom-2 md:bottom-4 right-2 md:right-4 object-contain self-end mt-4 w-6 aspect-square" />
      </a>
    </div>
  );
};

export default PlanCard;
