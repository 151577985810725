import HeaderOpaque from "components/Header/HeaderOpaque";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

const SiteHeader: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;

  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const threshold = 30; // Set the threshold for showing/hiding the header

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  const [isNavMobileOpen, setIsNavMobileOpen] = useState(false); // Add this state
  useEffect(() => {
    const handleScroll = () => {
      if (isNavMobileOpen) return; // Don't update if NavMobile is open

      const currentScrollY = window.scrollY;
      const shouldShowHeader = currentScrollY < 50 || (currentScrollY < lastScrollY && currentScrollY > 50 && lastScrollY - currentScrollY > threshold);

      const shouldHideHeader = currentScrollY > lastScrollY && currentScrollY > 50 && currentScrollY - lastScrollY > threshold;

      if (shouldShowHeader) {
        setShowHeader(true);
      } else if (shouldHideHeader) {
        setShowHeader(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, isNavMobileOpen]); // Add dependency on isNavMobileOpen

  const headerComponent = useMemo(() => (pathname === "/" ? <HeaderOpaque dark /> : pathname === "/services" ? <HeaderOpaque dark /> : <HeaderOpaque dark />), [pathname]);

  return <div className={`nc-SiteHeader ${showHeader ? "translate-y-0" : "-translate-y-full"} transition-transform duration-300 ease-in-out sticky top-0 w-full z-30`}>{headerComponent}</div>;
};

export default SiteHeader;
