import { ArrowDownCircleIcon, ArrowUpCircleIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";

interface FAQ {
  id: number;
  question: string;
  answer: string;
  longAnswer?: string;
  link: string;
}

interface FaqSectionProps {
  dataIds: number[];
}

export const faqs: FAQ[] = [
  {
    id: 1,
    question: "How quickly can I open a business account?",
    answer: "You can open a business account with FinVue in just five business days.",
    longAnswer: `<p>Once your application is submitted, we’ll work quickly to review and process the necessary details.</p>
                 <p>Our streamlined onboarding process ensures you get up and running without unnecessary delays.</p>`,
    link: "#",
  },
  {
    id: 2,
    question: "What do I need to open a business account?",
    answer: "To open a business account, you'll need to provide your business details and proof of identity.",
    longAnswer: `<p>Opening a FinVue Commercial account is a straightforward process:</p>
                 <ul class="list-disc ml-6">
                   <li>Complete an online form: The application form takes approximately 10 minutes to fill out.</li>
                   <li>Account review: Once submitted, we will review your business account application to ensure everything is in place.</li>
                   <li>Approval and setup: Once your application is approved, we will set up your account, and you’ll be ready to start using FinVue’s services.</li>
                 </ul>
                 <p>Please note that we may reach out to you to clarify details or request additional documentation, as required by our Know Your Business (KYB) and Know Your Customer (KYC) protocols. Any messages or guidance will be promptly shared to help you complete the process smoothly.</p>`,
    link: "#",
  },
  {
    id: 3,
    question: "How much will it cost to open a business account?",
    answer: "There is a one-time onboarding fee of £575.",
    longAnswer: `<p>This fee covers all administrative costs, including compliance checks for KYB and KYC, ensuring your account is fully secure and ready to operate.</p>
                 `,
    link: "#",
  },
  {
    id: 4,
    question: "How do I open a business account with Finvue Business?",
    answer: "You opening a FinVue Enterprise account with a simple and efficient process.",
    longAnswer: `<p>Opening a FinVue Enterprise account is a simple and efficient process:</p>
                 <ol class="list-decimal ml-6">
                   <li>Fill out the online application form: It takes approximately 10 minutes to complete.</li>
                   <li>Application review: Our team will review your submission as part of the Enterprise account setup process.</li>
                   <li>Approval and setup: Once approved, your account will be activated, and you’ll be ready to take advantage of FinVue’s premium business finance solutions.</li>
                 </ol>
                 <p>During this process, we may need to contact you for additional details or documents to ensure all requirements are met. Any guidance or updates will be shared promptly to keep the process on track.</p>`,
    link: "#",
  },
];

const FaqSection: React.FC<FaqSectionProps> = ({ dataIds }) => {
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({}); // Track expanded state for each FAQ
  const [longAnswerVisible, setLongAnswerVisible] = useState<{ [key: number]: boolean }>({}); // Track long answer visibility

  const toggleAnswer = (id: number) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    if (!expanded[id]) {
      setLongAnswerVisible((prevState) => ({ ...prevState, [id]: false })); // Reset long answer visibility
    }
  };

  const toggleLongAnswer = (id: number) => {
    setLongAnswerVisible((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const filteredFaqs = faqs.filter((faq) => dataIds.includes(faq.id));

  return (
    <section className="relative py-10 overflow-hidden text-slate-800">
      <div className="relative container px-4 mx-auto">
        <div className="max-w-full mx-auto">
          <div className="text-center mb-14">
            <h2 className="font-heading text-xl md:text-2xl font-bold mb-4 text-blue-700">Need a little more help?</h2>
            <h1 className="font-heading text-4xl md:text-5xl font-bold">Finvue Business FAQs</h1>
          </div>
          <div className="py-8 md:py-16 pb-10 px-8 rounded-xl sm:px-20 bg-neutral-100 dark:bg-black dark:bg-opacity-20 xl:rounded-[40px]">
            {filteredFaqs.map((faq) => (
              <div key={faq.id} className="flex flex-col mb-8 pb-8 border-b border-gray-700 text-left">
                {/* Question */}
                <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleAnswer(faq.id)}>
                  <h3 className="text-base md:text-xl font-semibold text-black">{faq.question}</h3>
                  <div>{expanded[faq.id] ? <ArrowUpCircleIcon className="h-6 w-6 text-black" /> : <ArrowDownCircleIcon className="h-6 w-6 text-black" />}</div>
                </div>

                {/* Answer */}
                {expanded[faq.id] && (
                  <div className="mt-3">
                    <div className="text-base text-gray-400">
                      {!longAnswerVisible[faq.id] ? (
                        <p>{faq.answer}</p>
                      ) : (
                        <div
                          className="text-gray-600"
                          dangerouslySetInnerHTML={{
                            __html: faq.longAnswer || faq.answer,
                          }}
                        />
                      )}
                    </div>
                    <button
                      className="mt-3 text-blue-600 hover:underline"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent question toggle
                        toggleLongAnswer(faq.id);
                      }}
                    >
                      {longAnswerVisible[faq.id] ? "Show less" : "Read more"}
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
