import { MAIN_NAVIGATION } from "data/navigation";
import React, { FC } from "react";
import NavigationItem, { NavItemType } from "./NavigationItem";

interface Props {
  className?: string;
  color?: "light" | "dark"; // Add the color prop
}

const Navigation: FC<Props> = ({ className = "flex", color = "dark" }) => {
  return (
    <ul className={`nc-Navigation items-center ${className}`}>
      {MAIN_NAVIGATION.map((item: NavItemType) => (
        <NavigationItem key={item.id} menuItem={item} color="light" />
      ))}
    </ul>
  );
};

export default Navigation;
