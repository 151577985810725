import { CarouselItem } from "data/types";
import React, { useEffect, useRef } from "react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface CenteredCarouselProps {
  items: CarouselItem[];
}

const CenteredCarousel: React.FC<CenteredCarouselProps> = ({ items }) => {
  const swiperRef = useRef<any>(null);

  useEffect(() => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      // Set initial scaling for active slide
      handleSlideChange(swiper);
    }
  }, []);

  const handleSlideChange = (swiper: any) => {
    swiper.slides.forEach((slide: HTMLElement, index: number) => {
      if (index === swiper.activeIndex) {
        slide.style.transform = "scale(1)"; // Slightly larger for active slide
        slide.style.transition = "transform 0.3s ease";
        slide.style.zIndex = "1"; // Bring active slide to the front
      } else {
        slide.style.transform = "scale(0.9)"; // Slightly smaller for inactive slides
        slide.style.transition = "transform 0.3s ease";
        slide.style.zIndex = "0"; // Send inactive slides to the back
      }
    });
  };

  return (
    <div className="carousel-container px-4 md:px-0 mx-auto overflow-hidden">
      <Swiper
        ref={swiperRef}
        centeredSlides={true}
        spaceBetween={10}
        grabCursor={true}
        loop={true}
        navigation={false}
        pagination={false}
        modules={[Navigation, Pagination]}
        onSlideChange={handleSlideChange}
        breakpoints={{
          1024: {
            slidesPerView: 4.5, // For large screens
          },
          768: {
            slidesPerView: 3.5, // For tablets
          },
          640: {
            slidesPerView: 2.5, // For small tablets
          },
          480: {
            slidesPerView: 1, // For mobile
          },
        }}
        style={{
          overflow: "hidden", // Restrict the Swiper overflow
        }}
      >
        {items.map((item) => (
          <SwiperSlide key={item.id}>
            <a
              href={item.link}
              className="block bg-gray-200 rounded-xl px-4 md:px-0"
              style={{
                position: "relative",
              }}
            >
              <div className="absolute top-8 left-8 right-4 text-left">
                <h3 className="text-2xl font-medium text-neutral-900">{item.title}</h3>
              </div>
              <div>
                <img src={item.image} alt={item.title} width={320} height={370} className="w-full object-contain pt-10 md:pt-12" />
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CenteredCarousel;
