import React from "react";
import DarkNavBar from "./DarkNavbar";
import WhiteNavBar from "./WhiteNavbar";

interface HeaderOpaqueProps {
  dark?: boolean;
  light?: boolean;
}

const HeaderOpaque: React.FC<HeaderOpaqueProps> = ({ dark, light }) => {
  return (
    <div className="nc-Header2 sticky top-0 w-full z-40">
      {dark ? (
        <DarkNavBar />
      ) : light ? (
        <WhiteNavBar />
      ) : (
        <WhiteNavBar /> // Default to WhiteNavBar if no prop is provided
      )}
    </div>
  );
};

export default HeaderOpaque;
