import ButtonPrimary from "components/Button/ButtonPrimary";
import FinVuePlans from "components/FinvueComponents/FinVuePlans/FinVuePlans";
import Heading from "components/Heading/Heading";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import Textarea from "components/Textarea/Textarea";
import GBFlag from "images/finvue/gb-flag.png";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { tokenToString } from "typescript";
import { sendEmail } from "../../../utils/mailerSend";

export interface ContactFormData {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  mobileNumber: string;
  subject: string;
  comment: string;
  recaptchaToken?: string;
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  companyName?: string;
  mobileNumber?: string;
  subject?: string;
  comment?: string;
}

const PageContact = () => {
  const [formData, setFormData] = useState<ContactFormData>({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    mobileNumber: "",
    subject: "",
    comment: "",
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  // Recaptcha
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);

  const validate = (): boolean => {
    const newErrors: FormErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required.";
    if (!formData.lastName) newErrors.lastName = "Last name is required.";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Valid email is required.";
    if (!formData.companyName) newErrors.companyName = "Company name is required.";
    if (!formData.mobileNumber || !/^\+?\d{7,15}$/.test(formData.mobileNumber)) newErrors.mobileNumber = "Valid mobile number is required.";
    if (!formData.subject) newErrors.subject = "Please add a Subject.";
    if (!formData.comment) newErrors.comment = "Please add a comment.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validate()) return;

    if (!recaptchaToken) {
      setMessage("Please complete the reCAPTCHA.");
      return;
    }

    setLoading(true);

    const result = await fetch("/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recaptchaToken, // Include the reCAPTCHA token
        formData,
        emailType: "contact",
      }),
    });

    const data = await result.json();

    if (data.success) {
      setMessage("Your message has been sent successfully!");
    } else {
      setMessage(data.error || "Failed to send your message.");
    }

    setLoading(false);
  };

  return (
    <section className="overflow-hidden">
      {/* Add a full-width 40px height bg-slate-800 bar */}
      <div className="w-full h-20 bg-slate-900"></div>
      <header className="container text-center w-full mx-auto pt-14 md:pt-20">
        <Heading isCenter desc="Our team is here to help with any questions or support you need. Reach out to us anytime!">
          <h4 className="text-slate-950 text-sm md:text-lg font-bold">Get Help</h4>
          <h3 className="text-2xl md:text-5xl font-black">
            Got a question? <span className="text-blue-700">Let’s talk!</span>
          </h3>
        </Heading>
      </header>

      <div className="container max-w-3xl py-10">
        <form className="grid gap-6" onSubmit={handleSubmit} noValidate>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <label className="block">
              <Label>First name</Label>
              <Input name="firstName" value={formData.firstName} onChange={handleChange} placeholder="John" />
              {errors.firstName && <p className="text-sm text-red-500">{errors.firstName}</p>}
            </label>
            <label className="block">
              <Label>Last name</Label>
              <Input name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Doe" />
              {errors.lastName && <p className="text-sm text-red-500">{errors.lastName}</p>}
            </label>
          </div>

          <label className="block">
            <Label>Email</Label>
            <Input name="email" value={formData.email} onChange={handleChange} placeholder="example@example.com" />
            {errors.email && <p className="text-sm text-red-500">{errors.email}</p>}
          </label>

          <label className="block">
            <Label>Company Name</Label>
            <Input name="companyName" value={formData.companyName} onChange={handleChange} placeholder="Company Inc." />
            {errors.companyName && <p className="text-sm text-red-500">{errors.companyName}</p>}
          </label>

          <label className="block">
            <Label>Mobile Number</Label>
            <div className="relative">
              <span className="absolute top-1/2 left-3 transform -translate-y-1/2">
                <img src={GBFlag} alt="GB Flag" className="h-4 w-6" />
              </span>
              <Input name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} placeholder="+44 123 456 789" className="pl-12" />
            </div>
            {errors.mobileNumber && <p className="text-sm text-red-500">{errors.mobileNumber}</p>}
          </label>

          <label className="block">
            <Label>Subject</Label>
            <Input name="subject" value={formData.subject} onChange={handleChange} placeholder="e.g., Request more information" />
            {errors.subject && <p className="text-sm text-red-500">{errors.subject}</p>}
          </label>

          <label className="block">
            <Label>Comment</Label>
            <Textarea name="comment" value={formData.comment} onChange={handleChange} placeholder="Write your message here..." rows={4} />
            {errors.comment && <p className="text-sm text-red-500">{errors.comment}</p>}
          </label>

          <div className="mt-2">
            {recaptchaLoading && (
              <div className="flex justify-center mb-2">
                <div className="loader border-t-2 border-blue-500 rounded-full w-6 h-6 animate-spin"></div>
              </div>
            )}
            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || "fallback-site-key"} onChange={(token) => setRecaptchaToken(token)} />{" "}
            <ButtonPrimary className="mt-4" type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Submit Application"}
            </ButtonPrimary>
          </div>
          {message && <p className="text-center mt-4 text-sm text-blue-600">{message}</p>}
        </form>
      </div>
      <FinVuePlans />
    </section>
  );
};

export default PageContact;
