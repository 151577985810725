import React from "react";

interface FeatureRowProps {
  feature: string;
  values: string[];
}

const FeatureRow: React.FC<FeatureRowProps> = ({ feature, values }) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-6 items-center">
      <div className="bg-zinc-800 p-4 rounded-lg">
        <p className="text-xs md:text-sm text-gray-400">{feature}</p>
      </div>
      {values.map((value, index) => (
        <div key={index} className="flex items-center justify-center bg-zinc-800 p-4 my-1 rounded-lg">
          <p className="text-xs md:text-sm text-gray-100">{value}</p>
        </div>
      ))}
    </div>
  );
};

export default FeatureRow;
