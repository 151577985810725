import ButtonPrimary from "components/Button/ButtonPrimary";
import FinVuePlans from "components/FinvueComponents/FinVuePlans/FinVuePlans";
import Heading from "components/Heading/Heading";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import Textarea from "components/Textarea/Textarea";
import GBFlag from "images/finvue/gb-flag.png"; // Importing the GB flag image
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { sendEmail } from "../../../utils/mailerSend";

export interface JobApplicationFormData {
  firstName: string;
  lastName: string;
  email: string;
  linkedInUrl: string;
  mobileNumber: string;
  expectedSalary: string;
  comment: string;
  recaptchaToken?: string;
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  linkedInUrl?: string;
  mobileNumber?: string;
  expectedSalary?: string;
  comment?: string;
}

const ApplyForJob: React.FC = () => {
  const [jobTitle, setJobTitle] = useState<string>("");
  const [formData, setFormData] = useState<JobApplicationFormData>({
    firstName: "",
    lastName: "",
    email: "",
    linkedInUrl: "",
    mobileNumber: "",
    expectedSalary: "",
    comment: "",
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [recaptchaLoading, setRecaptchaLoading] = useState(false);

  // Fetch the job title from the URL hash parameter
  useEffect(() => {
    const job = window.location.hash.substring(1); // Get hash without #
    setJobTitle(decodeURIComponent(job));
  }, []);

  const validate = (): boolean => {
    const newErrors: FormErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required.";
    if (!formData.lastName) newErrors.lastName = "Last name is required.";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Valid email is required.";
    if (!formData.linkedInUrl || !/^https?:\/\/.+\..+/i.test(formData.linkedInUrl)) newErrors.linkedInUrl = "Valid LinkedIn URL is required.";
    if (!formData.mobileNumber || !/^\+?\d{7,15}$/.test(formData.mobileNumber)) newErrors.mobileNumber = "Valid mobile number is required.";
    if (!formData.expectedSalary) newErrors.expectedSalary = "Expected salary is required.";
    if (!formData.comment) newErrors.comment = "Please add a comment.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate()) return;

    if (!recaptchaToken) {
      setMessage("Please complete the reCAPTCHA.");
      return;
    }

    setLoading(true);

    const result = await fetch("/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recaptchaToken,
        formData: {
          ...formData,
          comment: `Applying for the job: ${jobTitle}. ${formData.comment}`,
        },
        emailType: "jobApplication",
      }),
    });

    const data = await result.json();

    if (data.success) {
      setMessage("Your application has been submitted successfully!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        linkedInUrl: "",
        mobileNumber: "",
        expectedSalary: "",
        comment: "",
      });
    } else {
      setMessage(data.error || "Failed to submit your application.");
    }

    setLoading(false);
  };

  return (
    <section className="overflow-hidden">
      <div className="w-full h-20 bg-slate-900"></div>
      <header className="text-center max-w-4xl mx-auto pt-28">
        <Heading isCenter desc="Apply for a role at our company. Join a team where innovation thrives, and creativity meets purpose.">
          <h3 className="text-slate-950">Join our team and</h3>
          <h3 className="text-blue-700">create a difference.</h3>
        </Heading>
      </header>

      <div className="container max-w-3xl py-24">
        <form className="grid gap-6" onSubmit={handleSubmit} noValidate>
          <label className="block">
            <Label>Applying to the following job</Label>
            <Input value={jobTitle || "No job specified"} disabled className="mt-1 bg-gray-100 text-gray-500" />
          </label>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <label className="block">
              <Label>First name</Label>
              <Input name="firstName" value={formData.firstName} onChange={handleChange} placeholder="John" />
              {errors.firstName && <p className="text-sm text-red-500">{errors.firstName}</p>}
            </label>
            <label className="block">
              <Label>Last name</Label>
              <Input name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Doe" />
              {errors.lastName && <p className="text-sm text-red-500">{errors.lastName}</p>}
            </label>
          </div>

          <label className="block">
            <Label>Email</Label>
            <Input name="email" value={formData.email} onChange={handleChange} placeholder="example@example.com" />
            {errors.email && <p className="text-sm text-red-500">{errors.email}</p>}
          </label>

          <label className="block">
            <Label>LinkedIn URL</Label>
            <Input name="linkedInUrl" value={formData.linkedInUrl} onChange={handleChange} placeholder="https://linkedin.com/in/your-profile" />
            {errors.linkedInUrl && <p className="text-sm text-red-500">{errors.linkedInUrl}</p>}
          </label>

          <label className="block">
            <Label>Mobile Number</Label>
            <div className="relative">
              <span className="absolute top-1/2 left-3 transform -translate-y-1/2">
                <img src={GBFlag} alt="GB Flag" className="h-6 w-6" />
              </span>
              <Input name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} placeholder="+44 123 456 789" className="pl-12" />
            </div>
            {errors.mobileNumber && <p className="text-sm text-red-500">{errors.mobileNumber}</p>}
          </label>

          <label className="block">
            <Label>Expected Salary</Label>
            <Input name="expectedSalary" value={formData.expectedSalary} onChange={handleChange} placeholder="e.g., £50,000 per year" />
            {errors.expectedSalary && <p className="text-sm text-red-500">{errors.expectedSalary}</p>}
          </label>

          <label className="block">
            <Label>Add Comment</Label>
            <Textarea name="comment" value={formData.comment} onChange={handleChange} placeholder="Write your message here..." rows={4} />
            {errors.comment && <p className="text-sm text-red-500">{errors.comment}</p>}
          </label>

          <div className="mt-2">
            {recaptchaLoading && (
              <div className="flex justify-center mb-2">
                <div className="loader border-t-2 border-blue-500 rounded-full w-6 h-6 animate-spin"></div>
              </div>
            )}
            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || "fallback-site-key"} onChange={handleRecaptchaChange} />
            <ButtonPrimary className="mt-4" type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Submit Application"}
            </ButtonPrimary>
          </div>
          {message && <p className="text-center mt-4 text-sm text-blue-600">{message}</p>}
        </form>
      </div>
      <FinVuePlans />
    </section>
  );
};

export default ApplyForJob;
