import { Transition } from "app/headlessui";
import NavMobile from "components/Navigation/NavMobile";
import usePathname from "hooks/usePathname";
import React, { Fragment, useEffect, useState } from "react";

export interface MenuBarProps {}
const MenuBar: React.FC<MenuBarProps> = () => {
  const [isVisable, setIsVisable] = useState(false);
  const pathname = usePathname();

  useEffect(() => {
    setIsVisable(false);
  }, [pathname]);

  useEffect(() => {
    if (isVisable) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isVisable]);

  const handleCloseMenu = () => setIsVisable(false);

  const renderContent = () => {
    return (
      <Transition show={isVisable} as={Fragment}>
        <div className="fixed inset-0 z-50">
          {/* Overlay */}
          <Transition.Child as={Fragment} enter="duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-neutral-900 bg-opacity-50" onClick={handleCloseMenu}></div>
          </Transition.Child>

          {/* Mobile Menu */}
          <Transition.Child
            as={Fragment}
            enter="transition duration-100 transform"
            enterFrom="opacity-0 -translate-x-full"
            enterTo="opacity-100 translate-x-0"
            leave="transition duration-150 transform"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-full"
          >
            <div className="fixed inset-y-0 left-0 w-full max-w-sm bg-white dark:bg-neutral-900 z-50">
              <NavMobile onClickClose={handleCloseMenu} />
            </div>
          </Transition.Child>
        </div>
      </Transition>
    );
  };

  return (
    <div>
      <button
        onClick={() => {
          setIsVisable(!isVisable);
        }}
        className="p-2.5 rounded-lg text-neutral-700 dark:text-neutral-300 focus:outline-none flex items-center justify-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" viewBox="0 0 20 20" fill="white">
          <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
        </svg>
      </button>

      {renderContent()}
    </div>
  );
};

export default MenuBar;
