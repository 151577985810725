import arrowRight from "images/arrow-right.svg";
import React from "react";
import StatCard from "./StatCard";
import StatRow from "./StatRow";

const GlobalStats: React.FC = () => {
  const topRowStats = [
    {
      title: "Global Presence",
      description: "Freelance with a global presence",
      iconSrc: "/arrow-right.svg",
    },
    {
      title: "Diverse Team",
      description: "Representing 5+ Nationalities",
      iconSrc: "/arrow-right.svg",
    },
    {
      title: "Extensive Reach",
      description: "Serving Clients 40+ clients",
      iconSrc: "/arrow-right.svg",
    },
  ];

  return (
    <main className="flex flex-col gap-6">
      <section className="w-full max-md:max-w-full">
        <StatRow stats={topRowStats} />
      </section>
      <section className="w-full max-md:max-w-full">
        <div className="flex max-md:flex-col">
          <div className="flex flex-col w-6/12 max-md:w-full">
            <StatCard title="Multilingual Environment" description="Averaging 5 Languages per Team" />
          </div>
          <div className="flex flex-col mt-6 md:mt-0 ml-0 md:ml-5 w-6/12 max-md:w-full">
            <StatCard title="Employee Satisfaction" description="Highly Rated Workplace" />
          </div>
        </div>
      </section>
    </main>
  );
};

export default GlobalStats;
