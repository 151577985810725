import CookiesPopup from "components/CookiesPopup/CookiesPopup";
import { Helmet } from "react-helmet-async";
import MyRoutes from "./routers";

function App() {
  return (
    <div className="bg-[#f8f8f8] text-base dark:bg-[#1E1E1E]/100 text-neutral-900 dark:text-neutral-200 font-body">
      <Helmet>
        <title>FinVue</title>
        <meta name="description" content="The Account Built For Businesses" />
        <meta name="keywords" content="Scheduling Pyaments, Physical and Virtual Cards, Batch Payments, Invoicing, Payroll as Services" />
      </Helmet>
      <MyRoutes />
      <CookiesPopup />
    </div>
  );
}

export default App;
