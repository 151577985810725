import { CheckIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import FeatureGroup from "./FeatureGroup";
import PlanCard from "./PlanCard";

const plans = [
  {
    title: "FinVue Commercial",
    price: "£575",
    description: "Designed to streamline payroll operations and improve financial performance.",
    iconSrc: "/arrow-right.svg",
  },
  {
    title: "FinVue Enterprise",
    price: "£475",
    description: "Designed for enterprises, providing scalable solutions for complex workflows and growth.",
    iconSrc: "/arrow-right.svg",
  },
];

const features = [
  {
    title: "Account Integration",
    items: [
      {
        feature: "Onboarding fee",
        values: ["£575", "£575"],
      },
      {
        feature: "GBP Sort Code, Account Number, and GB IBAN",
        values: ["✓", "✓"],
      },
      {
        feature: "Monthly",
        values: ["£250", "£250"],
      },
      {
        feature: "Incoming Payments",
        values: ["0.65%", "0.4%"],
      },
      {
        feature: "Outgoing Payments",
        values: ["0.75p", "0.3%"],
      },
      {
        feature: "Physical VISA card",
        values: ["£25", "£25"],
      },
      {
        feature: "Virtual VISA card",
        values: ["£25", "£25"],
      },
      {
        feature: "ATM Domestic",
        values: ["£1.50", "£1.50"],
      },
      {
        feature: "ATM International",
        values: ["£2.00", "£2.00"],
      },
      {
        feature: "Other card fees",
        values: ["Available upon request", "Available upon request"],
      },
    ],
  },
  // {
  //   title: "Transfers without borders",
  //   items: [
  //     {
  //       feature: "international transfers",
  //       values: ["No-fee", "No-fee"],
  //     },
  //     {
  //       feature: "No-fee local transfers",
  //       values: ["5", "10"],
  //     },
  //   ],
  // },
];

const PricingPlans: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0); // For mobile tabs

  return (
    <section className="bg-zinc-900 container max-w-5xl text-white p-8 rounded-lg">
      {/* Desktop Two Columns */}
      <div className="hidden md:grid grid-cols-3 gap-6">
        {/* Empty column for feature headers */}
        <div></div>
        {plans.map((plan, index) => (
          <PlanCard key={index} {...plan} />
        ))}
      </div>

      {/* Feature Groups */}
      <div className="hidden md:block">
        {features.map((group, index) => (
          <FeatureGroup key={index} {...group} />
        ))}
      </div>

      {/* Mobile Tabs */}
      <div className="md:hidden">
        {/* Tabs */}
        <div className="flex justify-center mb-6 space-x-4">
          {plans.map((plan, index) => (
            <button key={index} onClick={() => setActiveTab(index)} className={`px-4 py-2 text-sm rounded-lg ${activeTab === index ? "bg-white text-zinc-900" : "bg-zinc-800 text-white"}`}>
              {plan.title}
            </button>
          ))}
        </div>

        {/* Active Tab Content */}
        <PlanCard {...plans[activeTab]} />
        <div className="mt-6">
          {features.map((group, index) => (
            <FeatureGroup
              key={index}
              title={group.title}
              items={group.items.map((item) => ({
                feature: item.feature,
                values: [item.values[activeTab]], // Show only active tab's value
              }))}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingPlans;
