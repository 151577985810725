import React, { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";

interface AnimatedStatCardProps {
  value: number; // The main number to animate to
  prefix?: string; // Text before the number
  suffix?: string; // Text after the number
  label: string; // The label below the number
}

const AnimatedStatCard: React.FC<AnimatedStatCardProps> = ({ value, prefix = "", suffix = "", label }) => {
  const [hasAnimated, setHasAnimated] = useState(false); // Track if animation has already run
  const cardRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setHasAnimated(true);
            observer.disconnect(); // Stop observing after animation runs
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the element is in view
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => observer.disconnect(); // Cleanup observer on unmount
  }, []);

  return (
    <section ref={cardRef} className="flex overflow-hidden flex-col grow justify-center items-center px-4 py-16 w-full text-center bg-zinc-100 rounded-[30px] text-blue-950 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col max-w-full w-[268px]">
        <h2 className="self-center text-7xl font-bold tracking-tighter leading-none max-md:text-4xl">
          {prefix}
          {hasAnimated ? (
            <CountUp start={0} end={value} duration={2.5} separator="," />
          ) : (
            "0" // Show 0 before animation starts
          )}
          {suffix}
        </h2>
        <p className="mt-10 text-base font-medium tracking-tight leading-none">{label}</p>
      </div>
    </section>
  );
};

export default AnimatedStatCard;
