import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import FaqSection from "components/FaqSections/FaqSection";
import FinVuePlans from "components/FinvueComponents/FinVuePlans/FinVuePlans";
import Heading from "components/Heading/Heading";
import SectionHero from "components/SectionHero/SectionHero";

import Button from "components/Button/Button";
import ButtonPrimary from "components/Button/ButtonPrimary";
import AnimatedStatCard from "components/FinvueComponents/Stats/AnimatedStatCard";
import SectionTextImage from "components/SectionTextImages/SectionTextImage";
import rightImg from "images/about-hero-right.png";
import aboutUsBg from "images/about-us-bg.png";
import ourVisionImg from "images/finvue/our-vision.jpg";
import oneFeatureCenter from "images/one-section-cards-center.png";
import oneFeatureRight from "images/one-section-debit-card-right.png";
import React from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import StatCard from "./StatCard";

const statistics = [
  { value: 60, prefix: "£", suffix: "M+", label: "Money flow processed in 2024" },
  { value: 80, prefix: "", suffix: "K+", label: "Transactions completed in 2024" },
  { value: 2, prefix: "£", suffix: "M+", label: "Trusted in safeguarding accounts" },
];

const PageAbout = () => {
  return (
    <div className={`nc-PageAbout relative overflow-hidden`}>
      {/* ======== BG GLASS ======== */}

      <div className="">
        <SectionHero
          centerImg={aboutUsBg}
          className=""
          heading={
            <div className="px-8 md:px-0 text-4xl font-black lg:text-6xl text-slate-900">
              We change the way <span className="text-blue-600">you do business</span>
            </div>
          }
          btnTextPrimary="Getting started"
          btnPrimaryUrl="/sign-up"
          btnTextSecondary="Contact sales"
          btnSecondaryUrl="/contact"
          subHeading="Manage accounts, process payments, and expand with our all-in-one platform."
          subHeadingColor="text-slate-900 px-4"
        />
      </div>

      <div className="py-16 lg:py-28 space-y-16 lg:space-y-28">
        <section className="container">
          <div className="flex gap-5 max-md:flex-col">
            {statistics.map((stat, index) => (
              <div key={index} className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <AnimatedStatCard value={stat.value} prefix={stat.prefix} suffix={stat.suffix} label={stat.label} />
              </div>
            ))}
          </div>
        </section>
        <section>
          <Heading isCenter desc="FinVue is a Software-as-a-Service provider offering embedded finance to help your business grow.">
            <h3 className="text-slate-950">We are creating change through</h3>
            <h3 className="text-blue-700">technology and product innovation.</h3>
          </Heading>
        </section>
        <section className="container relative py-6">
          {/* <BackgroundSection /> */}
          <SectionTextImage
            leftImg={ourVisionImg}
            imageClasses="shadow-lg rounded-2xl"
            beforeTitle="Plan smarter"
            title="Our Vision"
            afterTitle="At FinVue, We aim to set the standard for embedded finance by continuously innovating and providing businesses with the tools they need to succeed. Our focus is on creating seamless and scalable financial solutions that drive growth, enhance customer experiences, and transform the financial services landscape. By staying ahead of industry trends and prioritising customer needs, we are committed to shaping the future of fintech."
            buttonText="Learn More"
            buttonLink="##"
          />
        </section>
        <section className="max-w-7xl container flex overflow-hidden flex-col justify-center items-center px-10 py-24 font-medium text-center rounded-3xl bg-zinc-100 mx-auto max-md:px-5 max-md:py-24">
          <div className="flex flex-col items-center max-w-full">
            <h2 className="text-xl tracking-tight leading-none text-slate-500">What are you waiting for?</h2>
            <h1 className="self-stretch mt-4 text-7xl font-black tracking-tighter leading-none text-zinc-900 max-md:max-w-full max-md:text-4xl">Join Finvue Business</h1>
            <div className="flex gap-2 mt-12 max-w-full text-xl leading-none">
              <Button sizeClass="py-3 px-4 sm:px-6" href="/sign-up" pattern="default">
                Sign up in minutes
              </Button>
              <Button sizeClass="py-3 px-4 sm:px-6" href="/contact" pattern="third">
                Contact sales
              </Button>
            </div>
          </div>
        </section>
        <section>
          <div className="relative py-16">
            <FaqSection dataIds={[1, 2, 3, 4]} />
          </div>
        </section>
      </div>
      <section>
        <FinVuePlans />
      </section>
    </div>
  );
};

export default PageAbout;
