import React from "react";
import { TabProps } from "../../data/types";

export const TabList: React.FC<TabProps> = ({ items, selectedTab, onSelect }) => {
  return (
    <nav className="flex justify-start gap-10 border-b border-gray-300 pb-2">
      {items.map((item) => (
        <button key={item.id} onClick={() => onSelect(item.id)} className={`text-sm font-bold tracking-wide uppercase ${selectedTab === item.id ? "text-blue-600 border-b-2 border-blue-600 pb-1" : "text-gray-800"} hover:text-blue-600`}>
          {item.label}
        </button>
      ))}
    </nav>
  );
};
