import ButtonPrimary from "components/Button/ButtonPrimary";
import React, { FC, ReactNode } from "react";
import Image from "../Image";

export interface SectionHeroProps {
  className?: string;
  rightImg?: string;
  leftImg?: string;
  centerImg?: string;
  centerImgClasses?: string;
  topHeading?: ReactNode;
  heading: ReactNode;
  subHeading: string;
  btnTextPrimary: string;
  btnPrimaryUrl?: string;
  btnTextSecondary?: string;
  btnSecondaryUrl?: string;
  subHeadingColor?: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  leftImg,
  centerImg,
  topHeading,
  heading,
  centerImgClasses,
  subHeading,
  btnTextPrimary,
  btnPrimaryUrl = "/",
  btnTextSecondary,
  btnSecondaryUrl = "/",
  subHeadingColor = "text-neutral-6000 dark:text-neutral-400",
}) => {
  const renderContent = () => (
    <div className={`w-screen max-w-full xl:max-w-2xl space-y-5 lg:space-y-7 ${!centerImg ? "pt-32 md:pt-6" : "py-32"}`}>
      <h1 className="text-base tracking-widest font-semibold -mb-2 md:-mb-6 uppercase">{topHeading}</h1>
      <h2 className="text-xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">{heading}</h2>
      <span className={`block text-base xl:text-lg ${subHeadingColor}`}>{subHeading}</span>
      <div className={`flex space-x-4 ${!centerImg ? "justify-center md:justify-start" : "justify-center"}`}>
        {!!btnTextPrimary && <ButtonPrimary href={btnPrimaryUrl}>{btnTextPrimary}</ButtonPrimary>}
        {!!btnTextSecondary && (
          <ButtonPrimary href={btnSecondaryUrl} className="bg-transparent border border-white text-white hover:bg-white hover:text-black">
            {btnTextSecondary}
          </ButtonPrimary>
        )}
      </div>
    </div>
  );

  return (
    <div className={`nc-SectionHero relative  ${className}`}>
      {/* Center Image Layout */}
      {centerImg && (
        <div className="relative flex flex-col items-center text-center ">
          <Image className={`absolute inset-0 w-full h-full object-cover object-top lg:object-bottom rounded-b-3xl z-0 ${centerImgClasses}`} src={centerImg} alt="About Us" />
          <div className="relative z-10 space-y-7 max-w-3xl -mb-8">{renderContent()}</div>
        </div>
      )}

      {/* Left Image Layout */}
      {!centerImg && leftImg && (
        <div className="flex flex-col lg:flex-row-reverse space-y-14 lg:space-y-0 lg:space-x-reverse lg:space-x-10 items-center lg:items-start text-center lg:text-left">
          <div className="lg:w-1/2 flex-grow flex justify-center items-center">
            <Image width={320} height={610} className="w-full" src={leftImg} alt="left" />
          </div>
          <div className="w-full flex-grow">{renderContent()}</div>
        </div>
      )}

      {/* Right Image Layout */}
      {!centerImg && rightImg && !leftImg && (
        <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 items-center text-center lg:text-left">
          <div className="w-full flex-grow">{renderContent()}</div>
          <div className="w-full flex justify-center items-center overflow-hidden">
            <Image width={320} height={610} className="max-w-sm md:max-w-xs py-0 md:py-14 -mb-80 md:-mb-0 animate-slide-up-mobile md:animate-fade-in-desktop" src={rightImg} alt="right" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionHero;
