import Link from "components/Link";
import React from "react";
import LogoSvg from "./LogoSvg";

export interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className = "flex-shrink-0" }) => {
  return (
    <Link href="/" className={`finvue-logo inline-block text-white ${className}`}>
      <LogoSvg />
    </Link>
  );
};

export default Logo;
