import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Disclosure } from "app/headlessui";
import Button from "components/Button/Button";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonClose from "components/ButtonClose/ButtonClose";
import Link from "components/Link";
import Logo from "components/Logo/Logo";
import SocialsList from "components/SocialsList/SocialsList";
import SwitchDarkMode from "components/SwitchDarkMode/SwitchDarkMode";
import { MAIN_NAVIGATION } from "data/navigation";
import React from "react";
import { NavItemType } from "./NavigationItem";

export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({ data = MAIN_NAVIGATION, onClickClose }) => {
  const _renderMenuChild = (item: NavItemType, itemClass = " pl-3 text-slate-200 font-medium ") => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={index} as="li">
            <Link
              href={{
                pathname: i.href || undefined,
              }}
              className={`flex text-sm rounded-lg hover:bg-slate-900 mt-0.5 pr-4 ${itemClass}`}
            >
              <span className={`py-2.5 ${!i.children ? "block w-full" : ""}`} onClick={onClickClose}>
                {i.name}
              </span>
              {i.children && (
                <span className="flex items-center flex-grow" onClick={(e) => e.preventDefault()}>
                  <Disclosure.Button as="span" className="flex justify-end flex-grow">
                    <ChevronDownIcon className="ml-2 h-4 w-4 text-slate-500" aria-hidden="true" />
                  </Disclosure.Button>
                </span>
              )}
            </Link>
            {i.children && <Disclosure.Panel>{_renderMenuChild(i, "pl-3 text-slate-600 dark:text-slate-400 ")}</Disclosure.Panel>}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure key={index} as="li" className="text-slate-300 dark:text-white">
        <Link
          className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-900 rounded-lg"
          href={{
            pathname: item.href || undefined,
          }}
        >
          <span className={!item.children ? "block w-full" : ""} onClick={onClickClose}>
            {item.name}
          </span>
          {item.children && (
            <span className="block flex-grow" onClick={(e) => e.preventDefault()}>
              <Disclosure.Button as="span" className="flex justify-end flex-grow">
                <ChevronDownIcon className="ml-2 h-4 w-4 text-neutral-500" aria-hidden="true" />
              </Disclosure.Button>
            </span>
          )}
        </Link>
        {item.children && <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>}
      </Disclosure>
    );
  };

  return (
    <div className="fixed divide-y divide-neutral-600 top-0 left-0 overflow-y-auto w-full h-screen z-50 py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-blue-950 dark:bg-neutral-900  ">
      <div className="flex justify-between items-center py-6 px-5 ">
        <Logo className="-mt-2" />

        <div className="flex items-center">
          <Button sizeClass="py-3 px-6 sm:px-6" href="/sign-up" pattern="white">
            Sign Up
          </Button>
          <span className="p-2">
            <ButtonClose className="text-white" onClick={onClickClose} />
          </span>
        </div>
      </div>
      <ul className="flex flex-col py-6 px-2 space-y-1">{data.map(_renderItem)}</ul>
      <div className="flex container flex-col py-4 text-slate-400 dark:text-slate-300 text-sm">
        <span>Streamline operations and save time with our innovative, user-friendly payment management solutions tailored for businesses of all sizes.</span>

        <div className="flex justify-between items-center mt-4">
          <SocialsList itemClass="w-7 text-slate-200 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl" />
          <span className="block">{/* <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" /> */}</span>
        </div>
      </div>
      <div className="flex items-center justify-between py-6 px-5 space-x-2">
        <Button sizeClass="py-3 px-4 sm:px-6" href="https://secure.finvue.co/login" pattern="white">
          Login
        </Button>
        <ButtonPrimary href={"/sign-up"} className="!px-10">
          Sign Up
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default NavMobile;
