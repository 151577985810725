import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Heading from "components/Heading/Heading";
import SectionHero from "components/SectionHero/SectionHero";

import SectionTextImage from "components/SectionTextImages/SectionTextImage";
import rightImg from "images/about-hero-right.png";
import featureRight from "images/finvue/availablemobile.png";
// images
import FaqSection from "components/FaqSections/FaqSection";
import FinVuePlans from "components/FinvueComponents/FinVuePlans/FinVuePlans";
import invoiceSimplifiedImg from "images/finvue/services/invoice-simplified-bg.jpg";
import payrollSolutionsImg from "images/finvue/services/payment-management.jpg";
import schedulePaymentsImg from "images/finvue/services/schedule-payments-bg.jpg";
import staySecureOnlineImg from "images/finvue/services/stay-secure-online.jpg";
import streamlinedBatchImg from "images/finvue/services/streamlined-batch.jpg";
import oneFeatureCenter from "images/one-section-cards-center.png";
import servicesBg from "images/services-bg.png";
import React from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import StatCard from "./StatCard";

const statistics = [
  { value: "60", label: "Global Customers" },
  { value: "150", label: "Payment Locations" },
  { value: "3", label: "Offices" },
];

const PageServices = () => {
  return (
    <div className={`nc-PageAbout relative`}>
      {/* ======== BG GLASS ======== */}

      <div className="bg-slate-800 rounded-b-3xl">
        <SectionHero
          centerImg={servicesBg}
          className="rounded-b-3xl"
          topHeading={<span className="text-white">Services</span>}
          heading={<span className="text-4xl px-4 md:px-0 font-black lg:text-5xl text-slate-50">Everything you need to run your business in one place</span>}
          btnTextPrimary="Getting started"
          btnPrimaryUrl="/sign-up"
          btnTextSecondary="Contact Sales"
          btnSecondaryUrl="/contact"
          subHeading="Manage accounts, process payments, and expand with our all-in-one platform."
          subHeadingColor="text-slate-200 px-4"
        />
      </div>
      {/* <BgGlassmorphism /> */}
      <section className="relative py-16">
        <div className="container">
          {/* <BackgroundSection /> */}
          <SectionTextImage
            leftImg={staySecureOnlineImg}
            imageClasses="shadow-xl"
            beforeTitle="Plan smarter"
            title={
              <h3 className="text-2xl md:text-5xl font-bold">
                Stay secure online <span className="text-blue-700 block">with virtual cards.</span>
              </h3>
            }
            afterTitle="Get added protection from online fraud with virtual debit cards. Perfect for tracking subscriptions and other online payments."
            buttonText="Learn More"
            buttonLink="/sign-up"
          />
        </div>
      </section>
      <section className="relative py-16">
        <div className="container">
          {/* <BackgroundSection /> */}
          <SectionTextImage
            rightImg={payrollSolutionsImg}
            imageClasses="shadow-xl"
            beforeTitle="Plan smarter"
            title={
              <h3 className="text-2xl md:text-5xl font-bold">
                Payroll Solutions <span className="text-blue-700 block">for your business.</span>
              </h3>
            }
            afterTitle="We provides a cutting-edge payroll management solution powered by Orenda technology, ensuring seamless and efficient payroll workflows."
            buttonText="Learn More"
            buttonLink="/sign-up"
          />
        </div>
      </section>
      <section className="relative py-16">
        <div className="container">
          {/* <BackgroundSection /> */}
          <SectionTextImage
            leftImg={invoiceSimplifiedImg}
            imageClasses="shadow-xl"
            beforeTitle="Plan smarter"
            title={
              <h3 className="text-2xl md:text-5xl font-bold">
                Invoice simplified <span className="text-blue-700 block">for effortless control.</span>
              </h3>
            }
            afterTitle="Take control of your billing with an intuitive platform that makes invoicing quick, easy, and stress-free, letting you focus on what matters most."
            buttonText="Learn More"
            buttonLink="/sign-up"
          />
        </div>
      </section>
      <section className="relative py-16">
        <div className="container">
          {/* <BackgroundSection /> */}
          <SectionTextImage
            rightImg={streamlinedBatchImg}
            imageClasses="shadow-xl"
            beforeTitle="Plan smarter"
            title={
              <h3 className="text-2xl md:text-5xl font-bold">
                Streamlined <span className="text-blue-700 block">batch payments.</span>
              </h3>
            }
            afterTitle="Simplify your payment process with our intuitive batch payment feature, enabling you to pay multiple recipients quickly and securely, saving you time and effort."
            buttonText="Learn More"
            buttonLink="/sign-up"
          />
        </div>
      </section>
      <section className="relative py-16">
        <div className="container">
          {/* <BackgroundSection /> */}
          <SectionTextImage
            leftImg={schedulePaymentsImg}
            imageClasses="shadow-xl"
            beforeTitle="Plan smarter"
            title={
              <h3 className="text-2xl md:text-5xl font-bold">
                Scheduled payments <span className="text-blue-700 block">made easy.</span>
              </h3>
            }
            afterTitle="Plan and automate your payments in advance for seamless, on-time transactions, removing the need for manual processing and ensuring consistency every time."
            buttonText="Learn More"
            buttonLink="/sign-up"
          />
        </div>
      </section>

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        {/* <section className="rounded-[30px]">
          <div className="flex gap-5 max-md:flex-col">
            {statistics.map((stat, index) => (
              <div key={index} className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
                <StatCard value={stat.value} label={stat.label} />
              </div>
            ))}
          </div>
        </section> */}
        <section>
          <div className="relative py-16">
            <FaqSection dataIds={[1, 2, 3, 4]} />
          </div>
        </section>
      </div>
      <section>
        <FinVuePlans />
      </section>
    </div>
  );
};

export default PageServices;
