import officeImg from "images/finvue/office-london.jpg";
import React from "react";

const MapImage: React.FC = () => {
  return (
    <div className="max-h-[450px] overflow-hidden rounded-3xl">
      <img loading="lazy" src={officeImg} alt="UK Head Office Map" className="object-cover w-full h-full" />
    </div>
  );
};

export default MapImage;
