import React from "react";
import CallToAction from "./CallToAction";
import PlanCard from "./PlanCard";

interface PlanData {
  title: string;
  description: string;
  iconSrc: string;
}

const planData: PlanData[] = [
  {
    title: "FinVue Commercial",
    description: "Designed for businesses to streamline payroll operations, optimise cash flow, and enhance financial performance with advanced tools.",
    iconSrc: "/arrow-right.svg",
  },
  {
    title: "FinVue Enterprise",
    description: "Built for SME’s enterprises, offering scalable solutions to manage complex workflows and drive strategic growth.",
    iconSrc: "/arrow-right.svg",
  },
];

const FinVuePlans: React.FC = () => {
  return (
    <main className="flex flex-col items-center px-0 pt-24 bg-blue-950 pb-14 max-md:px-5 max-md:py-14">
      <section className="flex container flex-col items-start ">
        <h1 className=" text-3xl md:text-4xl font-medium tracking-tight leading-none text-white">Choose your plan</h1>
        <div className="self-stretch mt-6 md:mt-14">
          <div className="flex gap-2 md:gap-5 max-md:flex-col">
            {planData.map((plan, index) => (
              <PlanCard key={index} {...plan} />
            ))}
          </div>
        </div>
        <CallToAction />
      </section>
    </main>
  );
};

export default FinVuePlans;
