"use client";
import React, { useState } from "react";
import { TabItem } from "../../data/types";
import CookiePolicy from "./LegalPages/CookiePolicy";
import Disclaimer from "./LegalPages/Disclaimer";
import PrivacyPolicy from "./LegalPages/PrivacyPolicy";
import TermsAndConditions from "./LegalPages/TermsAndConditions";
import { TabDropdown } from "./TabDropdown";
import { TabList } from "./TabList";

const LegalPage: React.FC = () => {
  const tabs: TabItem[] = [
    { id: "privacy", label: "Privacy Policy" },
    { id: "cookie", label: "Cookie Policy" },
    { id: "disclaimer", label: "Disclaimer" },
    { id: "terms", label: "Terms & Conditions Monavate" },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0].id);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const renderSelectedPage = () => {
    switch (selectedTab) {
      case "privacy":
        return <PrivacyPolicy />;
      case "cookie":
        return <CookiePolicy />;
      case "disclaimer":
        return <Disclaimer />;
      case "terms":
        return <TermsAndConditions />;
      default:
        return <PrivacyPolicy />;
    }
  };

  return (
    <>
      {/* Add a full-width 40px height bg-slate-800 bar */}
      <div className="w-full h-20 bg-slate-900"></div>
      <main className="flex flex-col items-center">
        <section className="w-full bg-gray-200 py-28">
          <div className="container">
            <h1 className="text-3xl font-extrabold text-gray-900">Legal</h1>
            <p className="mt-4 text-gray-700">Transparency is a recurring FinVue value reflected in our pricing promise, security protocol, and legal agreements.</p>
            <div className="mt-6">
              <div className="hidden md:block">
                {/* Desktop Tabs */}
                <TabList items={tabs} selectedTab={selectedTab} onSelect={setSelectedTab} />
              </div>
              <div className="block md:hidden">
                {/* Mobile Dropdown */}
                <TabDropdown items={tabs} selectedTab={selectedTab} onSelect={setSelectedTab} isOpen={isDropdownOpen} onToggle={() => setIsDropdownOpen(!isDropdownOpen)} />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="mt-8">{renderSelectedPage()}</div>
        </section>
      </main>
    </>
  );
};

export default LegalPage;
