import React from "react";
import MapImage from "./MapImage";
import OfficeCard from "./OfficeCard";

interface ContactOfficeProps {}

const ContactOffice: React.FC<ContactOfficeProps> = () => {
  return (
    <section className="rounded-none ">
      <div className="flex max-md:flex-col rounded-3xl items-stretch bg-zinc-100">
        <div className="flex flex-col w-[40%] max-md:w-full">
          <OfficeCard
            title="Head Office"
            address={
              <span>
                Level 18, 40 Bank Street
                <br />
                Canary Wharf
                <br />
                London, E14 5NR
                <br />
                United Kingdom
              </span>
            }
            phone="+44 0330 043 35 36"
            email="info@finvue.co.uk"
          />
        </div>
        <div className="flex flex-col w-[60%] max-md:w-full">
          <MapImage />
        </div>
      </div>
    </section>
  );
};

export default ContactOffice;
