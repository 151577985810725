import { NavItemType } from "components/Navigation/NavigationItem";
import _ from "lodash";
import { Route } from "routers/types";

const randomId = _.uniqueId;

const OTHER_PAGE_CHILD: NavItemType[] = [
  // author pages ----------------
  {
    id: randomId(),
    href: "/about",
    name: "About",
  },
  {
    id: randomId(),
    href: "/careers",
    name: "Careers",
  },
  {
    id: randomId(),
    href: "/contact",
    name: "Contact us",
  },
];

export const MAIN_NAVIGATION: NavItemType[] = [
  {
    id: randomId(),
    href: "/" as Route,
    name: "Overview",
  },

  //
  {
    id: randomId(),
    href: "/services" as Route,
    name: "Services",
  },

  {
    id: randomId(),
    href: "/plans" as Route,
    name: "Plans",
  },
  {
    id: randomId(),
    href: "/",
    name: "Company",
    type: "dropdown",
    children: OTHER_PAGE_CHILD,
  },
];
