import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Popover, Transition } from "app/headlessui";
import Link from "components/Link";
import React, { FC, Fragment, useState } from "react";
import { Route } from "routers/types";

export interface NavItemType {
  id: string;
  name: string;
  href: Route;
  targetBlank?: boolean;
  children?: NavItemType[];
  type?: "dropdown" | "megaMenu" | "none";
  isNew?: boolean;
}

export interface NavigationItemProps {
  menuItem: NavItemType;
  color?: "light" | "dark"; // Add the color prop
}

const NavigationItem: FC<NavigationItemProps> = ({ menuItem, color = "dark" }) => {
  const [menuCurrentHovers, setMenuCurrentHovers] = useState<string[]>([]);

  const onMouseEnterMenu = (id: string) => {
    setMenuCurrentHovers((state) => [...state, id]);
  };

  const onMouseLeaveMenu = (id: string) => {
    setMenuCurrentHovers((state) => state.filter((item, index) => item !== id && index < state.indexOf(id)));
  };

  const textColor = color === "light" ? "text-white hover:text-gray-300" : "text-black hover:text-gray-700";
  const iconColor = color === "light" ? "text-white" : "text-black";

  const renderMainItem = (item: NavItemType) => {
    return (
      <div className="h-12 flex-shrink-0 flex items-center">
        <Link
          className={`inline-flex items-center text-sm lg:text-[15px] font-medium py-2.5 px-4 xl:px-5 rounded-full ${textColor}`}
          href={{
            pathname: item.href || undefined,
          }}
        >
          {item.name}
          {item.type && <ChevronDownIcon className={`ml-1 -mr-1 h-4 w-4 ${iconColor}`} aria-hidden="true" />}
        </Link>
      </div>
    );
  };

  const renderDropdownMenu = (menuDropdown: NavItemType) => {
    const isHover = menuCurrentHovers.includes(menuDropdown.id);
    return (
      <Popover as="li" className="menu-item menu-dropdown relative" onMouseEnter={() => onMouseEnterMenu(menuDropdown.id)} onMouseLeave={() => onMouseLeaveMenu(menuDropdown.id)}>
        {() => (
          <>
            <Popover.Button as={Fragment}>{renderMainItem(menuDropdown)}</Popover.Button>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-150"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel static className="sub-menu absolute transform z-10 w-56 top-full left-0">
                <ul className="rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 bg-slate-900 py-4 grid space-y-1">
                  {menuDropdown.children?.map((item) => (
                    <li key={item.id}>{renderMainItem(item)}</li>
                  ))}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  switch (menuItem.type) {
    case "dropdown":
      return renderDropdownMenu(menuItem);
    default:
      return <li className="menu-item flex-shrink-0">{renderMainItem(menuItem)}</li>;
  }
};

export default NavigationItem;
