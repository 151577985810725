import React from "react";
import FeatureCard from "./FeatureCard";

const featureData = [
  {
    number: 1,
    title: "Supportive and Flexible Work Environment",
    description: "Thrive in a setting that adapts to your needs, promoting work-life balance and productivity.",
  },
  {
    number: 2,
    title: "Work Remotely with a 30-Day 'Workation'",
    description: "Combine work and travel with our unique 'workation' policy, allowing you to explore the world without taking time off.",
  },
  {
    number: 3,
    title: "Professional Development Opportunities",
    description: "Grow your skills with access to training programmes, workshops, and career advancement resources.",
  },
];

const FeatureCards: React.FC = () => {
  return (
    <main className="flex gap-5 max-md:flex-col">
      {featureData.map((feature, index) => (
        <div key={index} className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
          <FeatureCard number={feature.number} title={feature.title} description={feature.description} />
        </div>
      ))}
    </main>
  );
};

export default FeatureCards;
