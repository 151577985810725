import NcLink from "components/NcLink/NcLink";
import { Feature } from "data/types";
import { color } from "framer-motion";
import React from "react";

interface FeatureSectionTwoColProps {
  features: Feature[];
}

const FeatureSectionTwoCol: React.FC<FeatureSectionTwoColProps> = ({ features }) => {
  return (
    <section className="relative overflow-hidden">
      <div className="relative container mx-auto">
        <div className="max-w-7xl mx-auto flex flex-wrap">
          {features.map((feature) => (
            <div key={feature.id} className="w-full lg:w-1/2 px-0 md:px-4 mb-8">
              <div className={`relative h-[450px] md:h-[550px] flex items-end rounded-3xl ${feature.color === "light" ? "bg-slate-50" : "bg-slate-800"} overflow-hidden bg-gray-100 dark:bg-gray-800 group`}>
                <img
                  loading="lazy"
                  width={485}
                  height={440}
                  className={`absolute -bottom-16 md:-bottom-24 ${feature.imgPosition === "left" ? "left-0" : "-right-14 md:-right-24"} w-auto md:h-4/5 object-contain transition-transform duration-500 transform group-hover:scale-105`}
                  src={feature.image}
                  alt={feature.title}
                />
                <div className="absolute inset-0 bg-black bg-opacity-10 p-8 md:p-12 flex flex-col justify-between">
                  <div className=" text-left">
                    <h3 className={`text-3xl md:text-4xl ${feature.color === "light" ? "text-slate-800" : "text-slate-200"} font-semibold`}>{feature.title}</h3>
                    <span className={`block mt-3 text-sm ${feature.color === "light" ? "text-slate-800" : "text-slate-200"} font-normal`}>{feature.subtitle}</span>
                  </div>
                  <div className={`flex justify-${feature.imgPosition === "left" ? "end" : "start"}`}>
                    <NcLink href={feature.link}>
                      <div className="relative flex items-center">
                        <span className={`mr-2 text-sm md:text-base ${feature.color === "light" ? "text-slate-800" : "text-slate-200"}`}>Learn more</span>
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={`${feature.color === "light" ? "#1e293b" : "#fff"}`} aria-hidden="true" data-slot="icon" className="w-5 h-5">
                            <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd"></path>
                          </svg>
                        </span>
                      </div>
                    </NcLink>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureSectionTwoCol;
