import PageHome from "app/(home)/page";
import PageContact from "app/(others)/contact/page";

import ApplyForJob from "app/(others)/ApplyJob/ApplyForJob";
import SignUp from "app/(others)/SignUp/SignUp";
import PageAbout from "app/about/page";
import PageCareers from "app/Careers/Careers";
import LegalPage from "app/Legal/LegalPage";
import Page404 from "app/not-found";
import PagePlans from "app/Plans/Plans";
import PageServices from "app/Services/page";
import SiteHeader from "app/SiteHeader";
import Footer from "components/Footer/Footer";
import MusicPlayer from "components/MusicPlayer/MusicPlayer";
import React from "react";
import { Helmet } from "react-helmet-async";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Page } from "./types";

export const pages: Page[] = [
  { path: "/", component: PageHome, title: "FinVue", description: "The Account Built For Businesses" },

  // Finvue pages -------------------------------------------------------
  { path: "/about", component: PageAbout, title: "FinVue - About Us", description: "Learn more about FinVue." },
  { path: "/careers", component: PageCareers, title: "FinVue - Careers", description: "Open Careers available at FinVue." },
  { path: "/services", component: PageServices, title: "FinVue - Services", description: "Our services and features." },
  { path: "/plans", component: PagePlans, title: "FinVue - Plans", description: "Our plans and pricings." },
  { path: "/contact", component: PageContact, title: "FinVue - Contact Us", description: "Contact our dedicated team." },
  { path: "/legal", component: LegalPage, title: "FinVue - Legal", description: "All the legal documents." },
  { path: "/apply", component: ApplyForJob, title: "FinVue - Apply for Job", description: "Apply for Job with us." },
  { path: "/sign-up", component: SignUp, title: "FinVue - Sign Up for an Account", description: "Sign up to open an account with us." },

  { path: "/page404", component: Page404 },
];

const MyRoutes = () => {
  return (
    <BrowserRouter>
      <SiteHeader />

      <Routes>
        {pages.map(({ component: Component, path, title, description, keywords }, index) => (
          <Route
            key={index}
            path={path}
            element={
              <>
                <Helmet>
                  <title>{title}</title>
                  {description && <meta name="description" content={description} />}
                  {keywords && <meta name="keywords" content={keywords} />}
                </Helmet>
                <Component />
              </>
            }
          />
        ))}
        <Route path="*" element={<Page404 />} />
      </Routes>

      <Footer />
      <MusicPlayer />
    </BrowserRouter>
  );
};

export default MyRoutes;
