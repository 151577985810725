import { CheckIcon } from "@heroicons/react/24/outline";
import React from "react";
interface PlanCardProps {
  title: string;
  price: string;
  description: string;
  iconSrc: string;
}

const PlanCard: React.FC<PlanCardProps> = ({ title, price, description, iconSrc }) => {
  return (
    <div className="flex flex-col p-6 bg-black rounded-lg text-white space-y-4">
      <h3 className="text-xl font-semibold">{title}</h3>
      {/* <p className="text-2xl font-bold">{price}</p> */}
      <p className="text-base text-gray-400">{description}</p>
      {/* <img src={iconSrc} alt="" className="w-8 h-8 mt-4" /> */}
      <CheckIcon className="w-8 h-8 bg-slate-900 p-1.5" />
    </div>
  );
};

export default PlanCard;
