import { ArrowTopRightOnSquareIcon, ArrowUturnLeftIcon, ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

const PrivacyPolicy: React.FC = () => {
  const linkItems = [
    { text: "Data", icon: ChevronRightIcon, target: "data" },
    { text: "Data retention", icon: ArrowTopRightOnSquareIcon, target: "data-retention" },
    {
      text: "Scope of this privacy policy",
      icon: ChevronRightIcon,
      target: "scope-privacy",
    },
    { text: "Your rights", icon: ArrowUturnLeftIcon, target: "your-rights" },
    {
      text: "Data collected",
      icon: ChevronRightIcon,
      target: "data-collected",
    },
    { text: "Links to other websites", icon: XMarkIcon, target: "links-other-websites" },
    { text: "How we collect Data", icon: ArrowTopRightOnSquareIcon, target: "how-we-collect-data" },
    { text: "Changes of business ownership and control", icon: ChevronRightIcon, target: "changin-of-business" },
    { text: "Our use of Data", icon: ChevronRightIcon, target: "our-use-of-data" },
    { text: "General", icon: ChevronRightIcon, target: "general" },
    { text: "Who we share Data with", icon: ChevronRightIcon, target: "who-we-share-data-width" },
    { text: "Changes to this privacy policy", icon: ChevronRightIcon, target: "changes-to-this-policy" },
    { text: "Keeping Data secure", icon: ChevronRightIcon, target: "keep-data-secure" },
    { text: "Our use of Data", icon: ChevronRightIcon, target: "our-use-of-data" },
  ];

  return (
    <div className="flex flex-col items-center container pt-20">
      {/* Header Section */}
      <header className="w-full px-5">
        <h1 className="text-4xl font-extrabold text-gray-900">Privacy Notice</h1>
        <p className="mt-4 text-lg text-gray-700">This notice was last updated on 1 December 2024. </p>
      </header>

      {/* Main Content Section */}
      <section className="w-full px-5 mt-8 p-6">
        <p className="text-gray-800">If you have any questions about how we use your personal data, you can get in touch by one of the methods set out in the Contact us section.</p>
        <p className="mt-4 text-gray-800">If you have any questions about how we use your personal data, you can get in touch by one of the methods set out in the Contact us section.</p>
        <p className="mt-4 text-gray-800">
          This privacy policy applies between you, the User of this Website and the Orenda Financial Services Limited (Orenda), the collector and processor of information provided by you through this website. Orenda takes the privacy of your
          information very seriously. This privacy policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the Website. Please read this privacy policy carefully.
        </p>
        <p className="mt-4 text-gray-800">You can scroll down to read the entire notice, or you can jump to the section you are interested in by clicking on one of the headings below:</p>
      </section>

      {/* Link Section */}
      <div className="w-full mt-8 bg-gray-100 rounded-lg p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {linkItems.map((item, index) => {
            const IconComponent = item.icon; // Get the icon component dynamically
            return (
              <a
                key={index}
                href={`#${item.target}`} // Anchor link to the section
                className="flex items-center justify-between bg-white p-4 rounded-lg shadow-md hover:bg-gray-50 transition"
              >
                <span className="text-gray-900 font-medium">{item.text}</span>
                <IconComponent className="h-6 w-6" /> {/* Render the icon dynamically */}
              </a>
            );
          })}
        </div>
      </div>

      {/* Sections with IDs */}
      <section className="w-full px-5 mt-8 scroll-mt-20">
        <h2 className="text-4xl my-4">Definitions and interpretation</h2>
      </section>
      <section id="data" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Data</h3>
        <p className="text-gray-800">
          Data includes all information that you submit via the Website. This definition incorporates, where applicable, the definitions provided in relevant the Data Protection Laws including but not limited to, the Directive 96/46/EC (Data
          Protection Directive) or the GDPR, and any national implementing laws, regulations and secondary legislation; GDPR the General Data Protection Regulation (EU) 2016/679. Orenda Financial Services Limited (12404984) is a limited liability
          company incorporated in England and Wales, whose registered office is at St Martins House, 1 Gresham Street, London, England, EC2V 7BX.{" "}
        </p>
        <p className="text-gray-800">
          In this privacy policy, unless the context requires a different interpretation: The singular includes the plural and vice versa; references to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses, schedules or
          appendices of this privacy policy; a reference to a person includes firms, companies, government entities, trusts and partnerships; “including” is understood to mean “including without limitation”; reference to any statutory provision
          includes any modification or amendment of it; the headings and subheadings do not form part of this privacy policy.
        </p>
      </section>
      <section id="scope-privacy" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Scope of this privacy policy</h3>
        <p className="text-gray-800">
          This privacy policy applies only to the actions of Orenda and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide to social
          media websites.{" "}
        </p>
        <p className="text-gray-800">For purposes of the applicable Data Protection Laws, Orenda is the “data controller”. This means that Orenda determines the purposes for which, and the manner in which, your Data is processed.</p>
      </section>
      <section id="data-collected" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Data collected</h3>
        <p className="text-gray-800">We may collect the following Data, which includes personal Data, from you: </p>
        <p className="text-gray-800">
          Name; date of birth; gender; profession; contact Information such as email addresses and telephone numbers; financial information such as credit / debit card numbers; IP address (automatically collected); operating system (automatically
          collected); in each case, in accordance with this privacy policy.{" "}
        </p>
      </section>
      <section id="how-we-collect-data" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">How we collect Data</h3>
        <p className="text-gray-800">We collect Data in the following ways: </p>
        <ul className="list-disc pl-5 text-gray-800">
          <li>data is given to us by you;</li>
          <li>and data is collected automatically.</li>
        </ul>
        <p className="text-gray-800">
          Your data will be collected in a number of ways, for example when you contact us through the Website, by telephone, post, e-mail or through any other means; or when you register and set up an account to receive products/services. In each
          case the case is collected in accordance with this privacy policy. Data that is collected automatically to the extent that you access the Website, will include for example, information ( IP address, date, time, frequency) about your visit
          to the Website. This information helps us to make improvements to Website content and navigation.
        </p>
      </section>
      <section id="our-use-of-data" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Our use of Data</h3>
        <p className="text-gray-800">
          Any or all of the above Data may be required by us from time to time in order to provide you with the best possible service and experience when using our Website. Data will be used in accordance with this privacy policy.{" "}
        </p>
        <p className="text-gray-800">
          We may use your Data for the above purposes if we deem it necessary to do so for our legitimate interests. If you are not satisfied with this, you have the right to object in certain circumstances (see the section headed “Your rights”
          below).
        </p>
      </section>
      <section id="who-we-share-data-width" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Who we share Data with</h3>
        <p className="text-gray-800">We may share your Data with the following groups of people for the following reasons: </p>
        <ul className="list-disc pl-5 text-gray-800">
          <li>any of our group companies or affiliates </li>
          <li>the legal entity under the brand you have applied for your account or card </li>
          <li>third party service providers who provide services to us which require the processing of personal data ensure that AML requirements are met </li>
          <li>relevant authorities, if the authorities request this information we will need to provide this by law; </li>
        </ul>
        <p className="text-gray-800">In each case, data will be provided in accordance with this privacy policy.</p>
      </section>
      <section id="keep-data-secure" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Keeping Data secure</h3>
        <p className="text-gray-800">We will use technical and organisational measures to safeguard your Data, for example: access to your account is controlled by a password and a username that is unique to you. </p>
        <p className="text-gray-800">We store your Data on secure servers. Payment details are encrypted using SSL technology (typically you will see a lock icon or green address bar (or both) in your browser when we use this technology. </p>
        <p className="text-gray-800">
          Technical and organisational measures include measures to deal with any suspected data breach. If you suspect any misuse or loss or unauthorised access to your Data, please let us know immediately by contacting us via this email address:
          info@orenda.finance If you want detailed information from Get Safe Online on how to protect your information and your computers and devices against fraud, identity theft, viruses and many other online problems, please visit
          www.getsafeonline.org. Get Safe Online is supported by HM Government and leading businesses.{" "}
        </p>
      </section>
      <section id="data-retention" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Data retention</h3>
        <p className="text-gray-800">
          Unless a longer retention period is required or permitted by law, we will only hold your Data on our systems for the period necessary to fulfil the purposes outlined in this privacy policy or until you request that the Data be deleted. Even
          if we delete your Data, it may persist on backup or archival media for legal, tax or regulatory purposes.{" "}
        </p>
      </section>
      <section id="your-rights" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Your rights</h3>
        <p className="text-gray-800">You have the following rights in relation to your Data: </p>
        <ul className="list-disc pl-5 text-gray-800">
          <li>
            Right to access–the right to request (i) copies of the information we hold about you at any time, or (ii) that we modify, update or delete such information. If we provide you with access to the information we hold about you, we will not
            charge you for this, unless your request is “manifestly unfounded or excessive.” Where we are legally permitted to do so, we may refuse your request. If we refuse your request, we will tell you the reasons why.{" "}
          </li>
          <li>Right to correct – the right to have your Data rectified if it is inaccurate or incomplete. </li>
          <li>Right to erase – the right to request that we delete or remove your Data from our systems. </li>
          <li>Right to restrict our use of your Data – the right to “block” us from using your Data or limit the way in which we can use the data. </li>
          <li>Right to data portability – the right to request that we move, copy or transfer your Data. </li>
          <li>Right to object – the right to object to our use of your Data including where we use it for our legitimate Interests. </li>
        </ul>
        <p className="text-gray-800">
          To make enquiries, exercise any of your rights set out above, or withdraw your consent to the processing of your Data (where consent is our legal basis for processing your Data), please contact us via this email address: info@orenda.finance
          If you are not satisfied with the way a complaint you make in relation to your Data is handled by us, you may be able to refer your complaint to the relevant data protection authority. For the UK, this is the Information Commissioner’s
          Office (ICO). The ICO’s contact details can be found on their website at https://ico.org.uk/. It is important that the Data we hold about you is accurate and current. Please keep us informed if your Data changes during the period for which
          we hold it.
        </p>
      </section>
      <section id="links-other-websites" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Links to other websites</h3>
        <p className="text-gray-800">
          This Website may, from time to time, provide links to other websites. We have no control over such websites and are not responsible for the content of these websites. This privacy policy does not extend to your use of such websites. You are
          advised to read the privacy policy or statement of other websites prior to using them.{" "}
        </p>
      </section>
      <section id="changin-of-business" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Changes of business ownership and control</h3>
        <p className="text-gray-800">
          Orenda may, from time to time, expand or reduce our business and this may involve the sale and/or the transfer of control of all or part of Orenda. Data provided by Users will, where it is relevant to any part of our business so
          transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this privacy policy, be permitted to use the Data for the purposes for which it was originally supplied to Us. We may
          also disclose Data to a prospective purchaser of our business or any part of it. In the above instances, we will take steps with the aim of ensuring your privacy is protected.{" "}
        </p>
      </section>
      <section id="general" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">General</h3>
        <p className="text-gray-800">You may not transfer any of your rights under this privacy policy to any other person. We may transfer our rights under this privacy policy where we reasonably believe your rights will not be affected. </p>
        <p className="text-gray-800">
          If any court or competent authority finds that any provision of this privacy policy (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and
          the validity and enforceability of the other provisions of this privacy policy will not be affected.{" "}
        </p>
        <p className="text-gray-800">Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy. </p>
        <p className="text-gray-800">
          This Agreement will be governed by and interpreted according to the law of England and Wales. All disputes arising under the Agreement will be subject to the exclusive jurisdiction of the English and Welsh courts.{" "}
        </p>
      </section>
      <section id="changes-to-this-policy" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Changes to this privacy policy</h3>
        <p className="text-gray-800">
          Orenda reserves the right to change this privacy policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediately posted on the Website and you are deemed to have accepted the terms of the
          privacy policy on your first use of the Website following the alterations. You may contact Orenda by email at info@orenda.finance.{" "}
        </p>
        <p className="text-gray-800">Cards are issued by Monavate UAB (Company No. 305628001, authorised in Lithuania by the Bank of Lithuania to issue electronic money (e-money) and provide payment services (license number 92). </p>
      </section>
      <section id="lawful-basis" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Contact us & updates to this notice</h3>
        <p className="text-gray-800">If you have any questions about this notice, including any requests to exercise your legal rights, please contact our Data Protection Officer (DPO) using the details set out below:</p>
        <ul className="my-4 list-disc pl-5 text-gray-800">
          <li className="text-gray-800">Email address: dpo@finvue.co.uk </li>
          <li className="text-gray-800">Postal Address:</li>
          <li className="text-gray-800">Data Protection Officer, Taupe Global Systems Ltd</li>
          <li className="text-gray-800">Level 18, 40 Bank Street </li>
          <li className="text-gray-800">Canary Wharf, London, </li>
          <li className="text-gray-800">E14 5NR </li>
          <li className="text-gray-800">United Kingdom</li>
        </ul>
        <p className="text-gray-800">
          You can also contact or complain to your local supervisory authority (for example the UK Information Commissioner’s Office (ICO) here), however please consider contacting us first so that we can address your question directly.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
