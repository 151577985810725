import React from "react";
import StatCard from "./StatCard";

interface StatRowProps {
  stats: Array<{
    title: string;
    description: string;
    iconSrc?: string;
  }>;
}

const StatRow: React.FC<StatRowProps> = ({ stats }) => {
  return (
    <div className="flex gap-5 max-md:flex-col">
      {stats.map((stat, index) => (
        <div key={index} className="flex flex-col w-[33%] max-md:w-full">
          <StatCard title={stat.title} description={stat.description} iconSrc={stat.iconSrc} />
        </div>
      ))}
    </div>
  );
};

export default StatRow;
