// sections/SectionSliderServices.tsx
import Heading from "components/Heading/Heading";

import { CarouselItem } from "data/types";
import React, { ReactNode } from "react";
import CenteredCarousel from "./CenteredCarousel";

export interface SectionSliderServicesProps {
  className?: string;
  heading: ReactNode;
  subHeading: string;
  items: CarouselItem[];
}

const SectionSliderServices: React.FC<SectionSliderServicesProps> = ({ heading = "Suggestions for discovery", subHeading = "Popular places to recommend for you", className = "", items }) => {
  return (
    <div className={`nc-SectionSliderServices ${className}`}>
      <Heading desc={subHeading} className="pb-10 md:pb-24 container px-8" isCenter>
        {heading}
      </Heading>
      <CenteredCarousel items={items} />
    </div>
  );
};

export default SectionSliderServices;
