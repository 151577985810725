import React, { useEffect, useState } from "react";

const CookiesPopup: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Check localStorage for a flag indicating the popup has been shown
  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem("cookiesAccepted");
    if (!hasAcceptedCookies) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true"); // Save flag in localStorage
    setIsVisible(false); // Hide popup
  };

  const handleReject = () => {
    localStorage.setItem("cookiesAccepted", "true"); // Save flag for rejecting cookies
    setIsVisible(false); // Hide popup
  };

  if (!isVisible) return null; // Don't render if not visible

  return (
    <div className="fixed bottom-5 left-0 right-0 z-50 flex justify-end">
      <div className="bg-black text-white rounded-xl p-5 w-full mx-2 md:w-11/12 max-w-md shadow-lg">
        <h3 className="text-lg font-semibold">Choose your cookies</h3>
        <p className="text-sm mt-2 text-gray-400">
          Cookies help us to enhance your experience, tailor ads to your interests, and improve our website.{" "}
          <a href="/privacy-policy" className="underline hover:text-gray-200">
            Learn more and manage
          </a>
        </p>
        <div className="flex flex-col justify-center gap-4 mt-4">
          <button onClick={handleAccept} className="bg-white text-black font-semibold py-2 px-4 rounded-full w-full hover:bg-gray-100">
            Accept all
          </button>
          <button onClick={handleReject} className="bg-transparent border border-white text-gray-300 font-semibold py-2 px-4 rounded-full w-full hover:bg-gray-700">
            Reject non-essential cookies
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiesPopup;
