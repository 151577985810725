import ButtonPrimary from "components/Button/ButtonPrimary";
import FinVuePlans from "components/FinvueComponents/FinVuePlans/FinVuePlans";
import Heading from "components/Heading/Heading";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import Textarea from "components/Textarea/Textarea";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export interface SignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  mobileNumber: string;
  annualRevenue: string;
  planChoice: string;
  affiliateCode: string;
  recaptchaToken?: string;
}

interface FormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  companyName?: string;
  mobileNumber?: string;
  annualRevenue?: string;
  planChoice?: string;
  affiliateCode?: string;
}

const SignUp: React.FC = () => {
  const [formData, setFormData] = useState<SignUpFormData>({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    mobileNumber: "",
    annualRevenue: "",
    planChoice: "",
    affiliateCode: "",
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const validate = (): boolean => {
    const newErrors: FormErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required.";
    if (!formData.lastName) newErrors.lastName = "Last name is required.";
    if (!formData.companyName) newErrors.companyName = "Company Number is required.";
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = "Valid email is required.";
    if (!formData.mobileNumber || !/^\+?\d{7,15}$/.test(formData.mobileNumber)) newErrors.mobileNumber = "Valid mobile number is required.";
    if (!formData.annualRevenue || isNaN(Number(formData.annualRevenue))) newErrors.annualRevenue = "Annual revenue must be a valid number.";
    if (!formData.planChoice) newErrors.planChoice = "Please select a plan.";
    if (!formData.affiliateCode || !/^TPG\d{3}$/.test(formData.affiliateCode)) {
      newErrors.affiliateCode = "If you don't have the code, contact us via the contact form.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate()) return;

    if (!recaptchaToken) {
      setMessage("Please complete the reCAPTCHA.");
      return;
    }

    setLoading(true);

    const result = await fetch("/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recaptchaToken,
        formData,
        emailType: "signUp", // Specify the email type
      }),
    });

    const data = await result.json();

    if (data.success) {
      setMessage("Your signup request has been submitted successfully!");
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        mobileNumber: "",
        annualRevenue: "",
        planChoice: "",
        affiliateCode: "",
      });
    } else {
      setMessage(data.error || "Failed to submit your signup request.");
    }

    setLoading(false);
  };

  return (
    <section className="overflow-hidden">
      <div className="w-full h-20 bg-slate-900"></div>
      <header className="container text-center w-full mx-auto pt-14 md:pt-20">
        <Heading isCenter desc="Sign up for a FinVue account. Choose your plan and join our community today.">
          <h4 className="text-slate-950 text-sm md:text-lg font-bold">Get Started with</h4>
          <h3 className="text-2xl md:text-5xl font-black">
            <span className="text-blue-700">FinVue</span>
          </h3>
        </Heading>
      </header>

      <div className="container max-w-3xl py-8">
        <form className="grid gap-6" onSubmit={handleSubmit} noValidate>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <label className="block">
              <Label>First name</Label>
              <Input name="firstName" value={formData.firstName} onChange={handleChange} placeholder="John" />
              {errors.firstName && <p className="text-sm text-red-500">{errors.firstName}</p>}
            </label>
            <label className="block">
              <Label>Last name</Label>
              <Input name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Doe" />
              {errors.lastName && <p className="text-sm text-red-500">{errors.lastName}</p>}
            </label>
          </div>

          <label className="block">
            <Label>Email</Label>
            <Input name="email" value={formData.email} onChange={handleChange} placeholder="example@example.com" />
            {errors.email && <p className="text-sm text-red-500">{errors.email}</p>}
          </label>

          <label className="block">
            <Label>Company Name</Label>
            <Input name="companyName" value={formData.companyName} onChange={handleChange} placeholder="Apple Inc." />
            {errors.companyName && <p className="text-sm text-red-500">{errors.companyName}</p>}
          </label>

          <label className="block">
            <Label>Mobile Number</Label>
            <Input name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} placeholder="+44 123 456 789" />
            {errors.mobileNumber && <p className="text-sm text-red-500">{errors.mobileNumber}</p>}
          </label>

          <label className="block">
            <Label>Annual Revenue</Label>
            <Input name="annualRevenue" value={formData.annualRevenue} onChange={handleChange} placeholder="50000.00" />
            {errors.annualRevenue && <p className="text-sm text-red-500">{errors.annualRevenue}</p>}
          </label>

          <label className="block">
            <Label>Choose Plan</Label>
            <select name="planChoice" value={formData.planChoice} onChange={handleChange} className="block w-full border-gray-300 rounded-md shadow-sm">
              <option value="">Select a plan</option>
              <option value="FinVue Commercial">FinVue Commercial</option>
              <option value="FinVue Enterprise">FinVue Enterprise</option>
              <option value="Im not sure">I'm not sure</option>
            </select>
            {errors.planChoice && <p className="text-sm text-red-500">{errors.planChoice}</p>}
          </label>

          <label className="block">
            <Label>Affiliate Code</Label>
            <Input name="affiliateCode" value={formData.affiliateCode} onChange={handleChange} placeholder="Affiliate Code" />
            {errors.affiliateCode && (
              <p className="text-sm text-red-500">
                {errors.affiliateCode}{" "}
                <a href="/contact" className="text-blue-600 underline">
                  contact us via the contact form
                </a>
              </p>
            )}
          </label>

          <div className="mt-2">
            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || "fallback-site-key"} onChange={(token) => setRecaptchaToken(token)} />{" "}
            <ButtonPrimary className="mt-4" type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Sign Up"}
            </ButtonPrimary>
          </div>
          {message && <p className="text-center mt-4 text-sm text-blue-600">{message}</p>}
        </form>
      </div>
      <FinVuePlans />
    </section>
  );
};

export default SignUp;
