import { ArrowRightIcon } from "@heroicons/react/24/outline";
import React, { ReactNode } from "react";

interface OfficeCardProps {
  title: string;
  address: ReactNode;
  phone: string;
  email: string;
}

const OfficeCard: React.FC<OfficeCardProps> = ({ title, address, phone, email }) => {
  return (
    <article className="flex flex-col justify-between h-full p-8 text-slate-500">
      {/* Title */}
      <div className="my-4">
        <h2 className="text-3xl font-bold text-zinc-900">{title}</h2>
        <p className="mt-6 text-lg">{address}</p>
      </div>

      {/* Contact Info */}
      <div className="">
        <address className="not-italic text-lg leading-relaxed">
          <p>T: {phone}</p>
          <p>E: {email}</p>
        </address>
      </div>

      {/* Button */}
      <div className="my-4">
        <a
          href="https://www.google.com/maps/place/18,+40+Bank+St,+London+E14+5NR,+UK/@51.5025997,-0.01946,17z/data=!3m2!4b1!5s0x487602b99f3cb3d1:0x51f4d435afd6e50c!4m6!3m5!1s0x487602b9bb5d0d0d:0x214bbeee4f822460!8m2!3d51.5025997!4d-0.01946!16s%2Fg%2F11pvctwm81?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D"
          target="_new"
          className="inline-flex items-center px-6 py-3 text-white bg-zinc-900 rounded-full text-sm font-medium hover:bg-black"
        >
          <span>Open in Maps</span>
          <ArrowRightIcon className="w-5 h-5 ml-3" />
        </a>
      </div>
    </article>
  );
};

export default OfficeCard;
