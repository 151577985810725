import React from "react";

interface StatCardProps {
  title: string;
  description: string;
  iconSrc?: string;
}

const StatCard: React.FC<StatCardProps> = ({ title, description, iconSrc }) => {
  return (
    <div className="bg-slate-100 p-8 rounded-lg text-slate-800 flex items-center gap-4">
      {/* {iconSrc && <img src={iconSrc} alt={title} className="w-12 h-12" />} */}
      <div>
        <h4 className="text-xl md:text-2xl font-medium text-slate-800">{title}</h4>
        <p className="text-base mt-2">{description}</p>
      </div>
    </div>
  );
};

export default StatCard;
