import React, { CSSProperties, FC } from "react";

interface Props extends Partial<Omit<React.ImgHTMLAttributes<HTMLImageElement>, "style">> {
  fill?: boolean; // Whether the image should stretch to fill its container
  width?: number | string; // Image width
  height?: number | string; // Image height
  style?: CSSProperties; // Custom inline styles
  onLoadingComplete?: () => void; // Callback for when the image has loaded
}

const Image: FC<Props> = ({
  fill = false,
  className = "",
  alt = "",
  width,
  height,
  style,
  onLoadingComplete,
  loading = "lazy", // Default to lazy loading
  ...args
}) => {
  const imageStyles: CSSProperties = {
    ...(fill ? { objectFit: "cover", position: "absolute", inset: 0, width: "100%", height: "100%" } : {}),
    ...style,
  };

  return (
    <img
      {...args}
      className={className}
      alt={alt}
      width={fill ? undefined : width} // Don't pass width when fill is true
      height={fill ? undefined : height} // Don't pass height when fill is true
      style={imageStyles}
      loading={loading} // Lazy loading by default
      onLoad={onLoadingComplete}
    />
  );
};

export default Image;
