import { ArrowTopRightOnSquareIcon, ArrowUturnLeftIcon, ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

const Disclaimer: React.FC = () => {
  const linkItems = [
    { text: "No Professional Advice", icon: ChevronRightIcon, target: "no-professional-advice" },
    { text: "Accuracy of Information", icon: ArrowTopRightOnSquareIcon, target: "accuracy-of-information" },
    {
      text: "No Warranties",
      icon: ChevronRightIcon,
      target: "no-warranties",
    },
    { text: "Limitation of Liability", icon: ArrowUturnLeftIcon, target: "limitation-of-liability" },
    {
      text: "External Links",
      icon: ChevronRightIcon,
      target: "external-links",
    },
    { text: "Changes to This Disclaimer", icon: XMarkIcon, target: "changes-to-this-disclaimer" },

    { text: "Governing Law", icon: ArrowTopRightOnSquareIcon, target: "governing-law" },
    { text: "Contact Us", icon: ChevronRightIcon, target: "contact-us" },
  ];

  return (
    <div className="flex flex-col items-center container pt-20">
      {/* Header Section */}
      <header className="w-full px-5">
        <h1 className="text-4xl font-extrabold text-gray-900">Disclaimer</h1>
        <p className="mt-4 text-lg text-gray-700">This notice was last updated on 1 December 2024.  </p>
      </header>

      {/* Main Content Section */}
      <section className="w-full px-5 mt-8 p-6">
        <p className="text-gray-800">If you have any questions about how we use your personal data, you can get in touch by one of the methods set out in the Contact us section.</p>
      </section>

      {/* Link Section */}
      <div className="w-full mt-8 bg-gray-100 rounded-lg p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {linkItems.map((item, index) => {
            const IconComponent = item.icon; // Get the icon component dynamically
            return (
              <a
                key={index}
                href={`#${item.target}`} // Anchor link to the section
                className="flex items-center justify-between bg-white p-4 rounded-lg shadow-md hover:bg-gray-50 transition"
              >
                <span className="text-gray-900 font-medium">{item.text}</span>
                <IconComponent className="h-6 w-6" /> {/* Render the icon dynamically */}
              </a>
            );
          })}
        </div>
      </div>

      {/* Sections with IDs */}

      <section id="no-professional-advice" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">No Professional Advice</h3>
        <p className="text-gray-800">
          The information provided on the Finvue website is for general informational purposes only. It is not intended as, nor should it be considered, professional advice. You should consult with appropriate professionals for specific advice
          tailored to your situation.
        </p>
      </section>
      <section id="accuracy-of-information" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Accuracy of Information</h3>
        <p className="text-gray-800">
          While we strive to ensure that the information on our website is accurate and up-to-date, Finvue makes no warranties or representations, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the
          website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
        </p>
      </section>
      <section id="no-warranties" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">No Warranties</h3>
        <p className="text-gray-800">
          This website is provided “as is” without any representations or warranties, express or implied. Finvue makes no representations or warranties in relation to this website or the information and materials provided on this website.
        </p>
      </section>
      <section id="limitation-of-liability" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Limitation of Liability </h3>
        <p className="text-gray-800">
          In no event will Finvue be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with,
          the use of this website.
        </p>
      </section>
      <section id="external-links" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4"> External Links </h3>
        <p className="text-gray-800">
          Through this website, you may be able to link to other websites which are not under the control of Finvue. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a
          recommendation or endorse the views expressed within them.
        </p>
      </section>
      <section id="changes-to-this-disclaimer" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Changes to This Disclaimer</h3>
        <p className="text-gray-800">
          Finvue reserves the right to amend this disclaimer at any time. Any such changes will be posted on this page, and it is your responsibility to review this page periodically for updates. Your continued use of the website after any changes
          are made constitutes your acceptance of the new terms.
        </p>
      </section>
      <section id="governing-law" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4"> Governing Law</h3>
        <p className="text-gray-800">This disclaimer and any disputes related to it or the use of our website will be governed by and construed in accordance with the laws of England and Wales, without regard to its conflict of law principles.</p>
      </section>
      <section id="contact-us" className="w-full px-5 my-8 scroll-mt-20">
        <h3 className="text-2xl my-4">Contact Us</h3>
        <p className="text-gray-800">If you have any questions about our use of cookies or other technologies, please email us at info@finvue.co.uk</p>
      </section>
    </div>
  );
};

export default Disclaimer;
