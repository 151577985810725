import ButtonPrimary from "components/Button/ButtonPrimary";
import React, { FC, ReactNode } from "react";
import Image from "../Image";

export interface SectionTextImageProps {
  className?: string;
  rightImg?: string;
  leftImg?: string;
  imageClasses?: string;
  beforeTitle?: string; // New prop for text before title
  title: ReactNode; // Title (h2)
  afterTitle?: string; // New prop for text after title
  buttonText: string; // Text for the button
  buttonLink: string; // Link for the button
}

const SectionTextImage: FC<SectionTextImageProps> = ({ className = "", rightImg, leftImg, imageClasses, beforeTitle = "", title, afterTitle = "", buttonText, buttonLink }) => {
  return (
    <div className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center ${className}`}>
      {/* Conditionally render left image */}
      {leftImg && (
        <div className="flex-shrink-0 lg:w-2/5 mb-8 lg:mb-0 lg:mr-10">
          <Image alt="left-img" sizes="(max-width: 768px) 100vw, 50vw" className={`${imageClasses} rounded-3xl`} src={leftImg} />
        </div>
      )}

      {/* Text Content */}
      <div className={`lg:w-${leftImg && rightImg ? "3/5" : "full"} mb-8 lg:mb-0`}>
        {/* {beforeTitle && <span className="text-xs uppercase tracking-wider font-medium text-neutral-400">{beforeTitle}</span>} */}
        <h2 className="font-semibold text-3xl sm:text-5xl mt-3">{title}</h2>
        {afterTitle && <span className="block text-xl mt-8 text-neutral-500 dark:text-neutral-400">{afterTitle}</span>}
        <ButtonPrimary className="mt-8" href={buttonLink}>
          {buttonText}
        </ButtonPrimary>
      </div>

      {/* Conditionally render right image */}
      {rightImg && (
        <div className="flex-shrink-0 lg:w-2/5 lg:ml-10">
          <Image alt="right-img" sizes="(max-width: 768px) 100vw, 50vw" className={`${imageClasses}  rounded-3xl`} src={rightImg} />
        </div>
      )}
    </div>
  );
};

export default SectionTextImage;
